import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, removeCartItem, fetchCartByNumber } from "../../redux/actions/ShopActions";
import config from '../../config.json';

const NavBar = () => {
  const userId = localStorage.getItem("id");
  const cartId = localStorage.getItem("cartId");
  const dispatch = useDispatch();
  const totalPrice = useSelector(state => state.shop.totalPrice);
  const cartItems = useSelector(state => state.shop.cartItems);
  const totalItems = useSelector(state => state.shop.totalItems);
  const apiKey = config.apiKeys;
  const subtotalRef = useRef(null);

  console.log(totalPrice);
  console.log(cartItems);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleRemoveCartItem = (cartItemId) => {
    dispatch(removeCartItem(cartItemId));
  };

  return (
    <header id="header" className="header-v7">
      <div className="container-fluid clearfix">
        <button className="close-button" style={{ marginLeft: 1485, color: 'lemonchiffon' }} onClick={() => {}}>
          ×
        </button>
        <div id="Variante_1">
          <a href="/">
            <img
              src="/assets/images/Variante_1.png"
              alt="Fred Auto Repair"
              width="50"
              height="33"
              data-retina="/assets/images/Variante_1.png"
              data-width="70"
              data-height="33"
            />
          </a>
        </div>
        <div className="mobile-button v2">
          <span></span>
        </div>
        <div className="nav-wrap text-center">
          <nav id="mainnav" className="mainnav header-v7">
            <ul className="menu">
              <li className="active">
                <Link to="/">HOME</Link>
              </li>
              {/* <li>
                <Link to="/services">SERVICES</Link>
              </li> */}
              <li>
                <Link to="/gallery">GALLERY</Link>
              </li>
              <li>
                <Link to="#">PAGE</Link>
                <ul className="submenu">
                  <li>
                    <Link to="/about">About Company</Link>
                  </li>
                  <li>
                    <Link to="/ourteam">Our Team</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/shop">SHOP</Link>
              </li>
              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
              <li>
                <Link to="/login">CLIENT PORTAL LOGIN</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="flat-menu-extra clearfix">
          <ul>
            <li className="cart nav-top-cart-wrapper">
              <Link to="#">
                <span className="text">My cart is ${totalPrice}</span>
              </Link>
              <span className="total-product">{cartItems.length}</span>
              <div className="nav-shop-cart">
                <div className="widget_shopping_cart_content">
                  <div className="woocommerce-min-cart-wrap">
                    <ul className="woocommerce-mini-cart cart_list product_list_widget">
                      {cartItems.map((item) => (
                        <li key={item.id} className="woocommerce-mini-cart-item mini_cart_item">
                          <Link to="#" onClick={() => handleRemoveCartItem(item.id)} className="remove">
                            <span className="fa fa-close"></span>
                          </Link>
                          <Link to="#">
                            <img
                              width="150"
                              height="150"
                              src={item.product.img} // Assuming item.product has an imageUrl property
                              alt={item.product.name}
                            />
                            {item.product.name} {/* Assuming item.product has a name property */}
                          </Link>
                          <span className="quantity">
                            <strong> Quantity: {item.quantity} × </strong>{" "}
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">
                                ${item.amount}
                              </span>
                            </span>
                          </span>
                        </li>
                      ))}
                    </ul>
                    <p className="woocommerce-mini-cart__total total" ref={subtotalRef}>
                      <strong>Subtotal:</strong>{" "}
                      <span className="woocommerce-Price-amount amount">
                        <span className="woocommerce-Price-currencySymbol">${totalPrice}</span>
                      </span>
                    </p>
                    <p className="woocommerce-mini-cart__buttons buttons">
                      {/* <Link to="#" onClick={() => dispatch(fetchCartByNumber(userId))} className="button wc-forward">
                        View cart
                      </Link> */}
                      <Link to="/checkout" className="button-cart checkout wc-forward">
                        Checkout
                      </Link>
                    </p>
                  </div>
                  {/* <div className="promo"    >     
                  <h2>USE JULYDEAL  </h2>
                  <h2>Promo Code  </h2>
                  </div> */}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default NavBar;

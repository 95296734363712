// src/redux/actionTypes.js
import { useDispatch } from 'react-redux';
export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';
export const ADDUSER = 'ADDUSER';
export const AllSERVICES = 'AllSERVICES';
export const SAVEAPPOINTMENT = 'SAVEAPPOINTMENT';
export const REMOVEUSER = 'REMOVEUSER';
export const UPDATE_TOTAL_PRICE = 'UPDATE_TOTAL_PRICE';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const FETCH_TOTAL_PRICE_SUCCESS = 'FETCH_TOTAL_PRICE_SUCCESS';
export const FETCH_TOTAL_PRICE_FAILURE = 'FETCH_TOTAL_PRICE_FAILURE';
export const GET_ALL_CART_ITEMS = 'GET_ALL_CART_ITEMS';
export const GET_CART_ITEMS_BY_ID = 'GET_CART_ITEMS_BY_ID';
export const GET_CART_ITEMS_BY_USERID = 'GET_CART_ITEMS_BY_USERID';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';

export const FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE';

export const FETCH_CARS_REQUEST = 'FETCH_CARS_REQUEST';
export const FETCH_CARS_SUCCESS = 'FETCH_CARS_SUCCESS';
export const FETCH_CARS_FAILURE = 'FETCH_CARS_FAILURE';

export const FETCH_YEARS_REQUEST = 'FETCH_YEARS_REQUEST';
export const FETCH_YEARS_SUCCESS = 'FETCH_YEARS_SUCCESS';
export const FETCH_YEARS_FAILURE = 'FETCH_YEARS_FAILURE';

export const DELETE_CART_ITEM_SUCCESS = 'DELETE_CART_ITEM_SUCCESS';
export const DELETE_CART_ITEM_FAILURE = 'DELETE_CART_ITEM_FAILURE';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
 export const  UPDATE_TOTAL_ITEMS = ' UPDATE_TOTAL_ITEMS';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const FETCH_CAR_DETAILS_REQUEST = 'FETCH_CAR_DETAILS_REQUEST';
export const FETCH_CAR_DETAILS_SUCCESS = 'FETCH_CAR_DETAILS_SUCCESS';
export const FETCH_CAR_DETAILS_FAILURE = 'FETCH_CAR_DETAILS_FAILURE';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const TOGGLE_CAR_DETAIL = 'TOGGLE_CAR_DETAIL';
// export const FETCH_CAR_DETAILS_FAILURE = 'FETCH_CAR_DETAILS_FAILURE';
// export const export const ADD_CAR_DETAIL = 'ADD_CAR_DETAIL'; = 
export const ADD_CAR_DETAIL = 'ADD_CAR_DETAIL';

// function updateTotalItems(totalItems) {
//     return {
//         type: UPDATE_TOTAL_ITEMS,
//         payload: totalItems,
//     };
// }



import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMessages, sendMessage } from '../../redux/actions/ChatAction';
import './ChatWidget.css';

const userId = localStorage.getItem('id');
const userEmail = localStorage.getItem('email');
const ChatWidget = () => {
  const dispatch = useDispatch();
  const { loading, messages, error } = useSelector((state) => state.chat); // Access the 'chat' slice of state

  const [Sendmessage, setSendMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  console.log(messages);
  const filterMessagesByUserId = (messages, userId) => {
    return messages.filter((msg) => msg.receiver === userId);
  };

  const filteredMessages = filterMessagesByUserId(messages, userId);
  console.log(filteredMessages);
  useEffect(() => {
    dispatch(fetchMessages());
  }, [dispatch]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    dispatch(sendMessage(Sendmessage));
    setSendMessage('');
  };

  const handleToggleChat = () => {
    setIsOpen(!isOpen);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div className={`chat-widget ${isOpen ? 'open' : 'closed'}`}>
      <div className="chat-header" onClick={handleToggleChat}>
        <h2>Chat</h2> {userEmail && <p>{userEmail}</p>} 
      </div>
      {isOpen && (
        <>
          <div className="chat-messages">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>Error: {error}</p>
            ) : (
              filteredMessages.map((msg, index) => (
                <div key={index} className="chat-message">
                    <p>{formatTimestamp(msg.timestamp)}</p>
                    <h4>Serv.Rep{msg.sender}</h4>
                  <p>{msg.message}</p>
                  {/* <p>{msg.receiver}</p> */}
                </div>
              ))
            )}
          </div>
          <div className="chat-input">
            <form onSubmit={handleSendMessage}>
              <input
                type="text"
                value={Sendmessage}
                onChange={(e) => setSendMessage(e.target.value)}
                placeholder="Type a message..."
              />
              <button type="submit">Send</button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatWidget;

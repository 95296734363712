import React from "react";
const OurTeam = () => {
  return (
    <div>
      <section className="page-title  v3 clearfix parallax  parallax5">
        <div className="overlay"></div>
        <div className="container">
          <div className="wrap-page-title">
            <div className="breakcrums text-center v2">
              <ul>
                <li>
                  <a href="#" className="hover-text">
                    HOME
                  </a>
                </li>
                <li>
                  <a href="#" className="hover-text">
                    PAGES
                  </a>
                </li>
                <li>
                  <a href="#" className="hover-text">
                    OUR TEAM
                  </a>
                </li>
              </ul>
            </div>

            <div className="page-title-heading text-center v2">
              <h1>
                <a href="#" className="hover-text">
                  OUR TEAM
                </a>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <div className="content-wrap our-team-page ">
        <section className="flat-team  our-team-page bg-f6f6f6">
          <div className="container">
            <div
              className="flat-spacer clearfix"
              data-desktop="100"
              data-mobile="77"
              data-smobile="77"
            ></div>
            <div
              className="flat-carousel"
              data-auto="false"
              data-column="4"
              data-column2="2"
              data-column3="1"
              data-gap="30"
              data-dots="false"
              data-nav="false"
              data-loop="true"
            >
              <div className="flat-imgbox owl-carousel style6">
                <div className="imgbox style6   text-center">
                  <div className="imgbox-img">
                    <img
                      src="/assets/images/home3/member-1.png"
                      alt="AutoMov"
                    />
                  </div>
                  <div className="imgbox-content ">
                    <h3>
                      <a href="#">Mr. Dony</a>
                    </h3>
                    <p className="position">Sr. manager</p>
                    <p>
                      Our Senior Manager is responsible for the overall management and will make sure you are safety safe and satisfactional.
                    </p>
                    <div className="flat-socail">
                      <ul>
                        <li>
                          <a href="#" className="hover-text">
                            <span className=" social_facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_pinterest"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_googleplus"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="fa fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="imgbox style6  text-center">
                  <div className="imgbox-img">
                    <img
                      src="/assets/images/home3/member-2.png"
                      alt="AutoMov"
                    />
                  </div>
                  <div className="imgbox-content ">
                    <h3>
                      <a href="#">Tony Cruise</a>
                    </h3>
                    <p className="position">Sr. manager</p>
                    <p>
                      This Senior manager will get his hands dirty and and ensure the resourcdes your need are available to you.
                    </p>
                    <div className="flat-socail">
                      <ul>
                        <li>
                          <a href="#" className="hover-text">
                            <span className=" social_facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_pinterest"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_googleplus"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="fa fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="imgbox style6   text-center">
                  <div className="imgbox-img">
                    <img
                      src="/assets/images/home3/member-3.png"
                      alt="AutoMov"
                    />
                  </div>
                  <div className="imgbox-content ">
                    <h3>
                      <a href="#">Martin John</a>
                    </h3>
                    <p className="position">Sr. manager</p>
                    <p>
                      This Senior Manager is responsible for the cost cutting measures every company needs ,he is our 'we save , you save guru'
                    </p>
                    <div className="flat-socail">
                      <ul>
                        <li>
                          <a href="#" className="hover-text">
                            <span className=" social_facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_pinterest"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_googleplus"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="fa fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="imgbox style6   text-center">
                  <div className="imgbox-img">
                    <img
                      src="/assets/images/home3/member-4.png"
                      alt="AutoMov"
                    />
                  </div>
                  <div className="imgbox-content">
                    <h3>
                      <a href="#">Mac William</a>
                    </h3>
                    <p className="position">Sr. manager</p>
                    <p>
                      Is our Manufacturing Manager and is responsible for the manufacturing of our products.
                    </p>
                    <div className="flat-socail">
                      <ul>
                        <li>
                          <a href="#" className="hover-text">
                            <span className=" social_facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_pinterest"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_googleplus"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="fa fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="imgbox style6   text-center">
                  <div className="imgbox-img">
                    <img
                      src="/assets/images/home3/member-1.png"
                      alt="AutoMov"
                    />
                  </div>
                  <div className="imgbox-content ">
                    <h3>
                      <a href="#">Mr. McCant</a>
                    </h3>
                    <p className="position">Sr. manager</p>
                    <p>
                    Is our Manufacturing Manager and is responsible for the design of our products.
                    </p>
                    <div className="flat-socail">
                      <ul>
                        <li>
                          <a href="#" className="hover-text">
                            <span className=" social_facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_pinterest"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_googleplus"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="fa fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="imgbox style6  text-center">
                  <div className="imgbox-img">
                    <img
                      src="/assets/images/home3/member-2.png"
                      alt="AutoMov"
                    />
                  </div>
                  <div className="imgbox-content ">
                    <h3>
                      <a href="#">Mr. Gilbert</a>
                    </h3>
                    <p className="position">Sr. manager</p>
                    <p>
                    Is our Manufacturing Manager and is responsible for the automation of our manufacturing products.
                    </p>
                    <div className="flat-socail">
                      <ul>
                        <li>
                          <a href="#" className="hover-text">
                            <span className=" social_facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_pinterest"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_googleplus"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="fa fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="imgbox style6   text-center">
                  <div className="imgbox-img">
                    <img
                      src="/assets/images/home3/member-3.png"
                      alt="AutoMov"
                    />
                  </div>
                  <div className="imgbox-content ">
                    <h3>
                      <a href="#">Mr. Wakilah</a>
                    </h3>
                    <p className="position">Sr. manager</p>
                    <p>
                    Is our Manufacturing Manager and is responsible for the manufacturing Technology.
                    </p>
                    <div className="flat-socail">
                      <ul>
                        <li>
                          <a href="#" className="hover-text">
                            <span className=" social_facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_pinterest"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_googleplus"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="fa fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="imgbox style6   text-center">
                  <div className="imgbox-img">
                    <img
                      src="/assets/images/home3/member-4.png"
                      alt="AutoMov"
                    />
                  </div>
                  <div className="imgbox-content">
                    <h3>
                      <a href="#">Mr. Looty</a>
                    </h3>
                    <p className="position">Sr. manager</p>
                    <p>
                      Is our Manufacturing Manager and is responsible for the robotics of our products.
                    </p>
                    <div className="flat-socail">
                      <ul>
                        <li>
                          <a href="#" className="hover-text">
                            <span className=" social_facebook"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_twitter"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_pinterest"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="  social_googleplus"></span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="hover-text">
                            <span className="fa fa-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flat-spacer clearfix"
              data-desktop="100"
              data-mobile="77"
              data-smobile="77"
            ></div>
          </div>
        </section>

        <section className="flat-auto-motive our-team-page">
          <div className="overlay"></div>
          <div className="container ">
            <div
              className="flat-spacer clearfix"
              data-desktop="102"
              data-mobile="60"
              data-smobile="60"
            ></div>
            <div className="auto-motive-wrap clearfix">
              <div className="img one-of-two">
                <img
                  src="/assets/images/our-team/img-automov.png"
                  alt="AutoMov"
                />
              </div>
              <div className="content one-of-two">
                <div
                  className="flat-spacer clearfix"
                  data-desktop="48"
                  data-mobile="20"
                  data-smobile="20"
                ></div>
                <div className="title">
                  <a href="#">
                    <h3>Welcome to Fred Auto Repair </h3> <span></span>
                  </a>
                </div>
                <p>
                Welcome to Fred's Auto Repair, where we pride ourselves on providing exceptional service and quality repairs. Established in the 2000s, our tradition of excellence has made us a trusted name in the automotive industry.
                </p>
                <p>
                Over the centuries, we have evolved with the times, from the advent of the printing press to the digital age. Our commitment to innovation and customer satisfaction remains unwavering, as evidenced by our use of state-of-the-art equipment and techniques.
                </p>
                <div className="wrap-btn">
                  <a href="#" className="flat-button bg-read-more v2">
                    Read more
                  </a>
                </div>
              </div>
            </div>
            <div
              className="flat-spacer clearfix"
              data-desktop="70"
              data-mobile="70"
              data-smobile="70"
            ></div>
          </div>
        </section>

        <section className="flat-skills style2">
          <div className="container">
            <div
              className="flat-spacer clearfix"
              data-desktop="104"
              data-mobile="70"
              data-smobile="70"
            ></div>
            <div className="flat-title v4 text-center">
              <h2>OUR SKILLS</h2>
            </div>
            <div className="skills-wrap flat-border-bottom">
              <div
                className="flat-spacer clearfix"
                data-desktop="93"
                data-mobile="70"
                data-smobile="70"
              ></div>
              <div className="sub-col clearfix  ">
                <div className="one-of-two">
                  <div className="flat-progres">
                    <div className="progress-item">
                      <div className="name">Technology</div>
                      <div className="perc show">
                        <span>70%</span>
                      </div>
                      <div
                        className="progres-bar"
                        data-percent="70"
                        data-inviewport="yes"
                      >
                        <div
                          className="progress-animate"
                          data-duration="3000"
                        ></div>
                      </div>
                    </div>
                    <div className="progress-item">
                      <div className="name">Quality services</div>
                      <div className="perc show">
                        <span>75%</span>
                      </div>
                      <div
                        className="progres-bar"
                        data-percent="75"
                        data-inviewport="yes"
                      >
                        <div
                          className="progress-animate"
                          data-duration="3000"
                        ></div>
                      </div>
                    </div>
                    <div className="progress-item">
                      <div className="name">Reputation</div>
                      <div className="perc show">
                        <span>90%</span>
                      </div>
                      <div
                        className="progres-bar"
                        data-percent="90"
                        data-inviewport="yes"
                      >
                        <div
                          className="progress-animate"
                          data-duration="3000"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="one-of-two">
                  <div className="flat-progres">
                    <div className="progress-item">
                      <div className="name">Experience</div>
                      <div className="perc show">
                        <span>55%</span>
                      </div>
                      <div
                        className="progres-bar"
                        data-percent="55"
                        data-inviewport="yes"
                      >
                        <div
                          className="progress-animate"
                          data-duration="3000"
                        ></div>
                      </div>
                    </div>
                    <div className="progress-item">
                      <div className="name">Repair</div>
                      <div className="perc show">
                        <span>65%</span>
                      </div>
                      <div
                        className="progres-bar"
                        data-percent="65"
                        data-inviewport="yes"
                      >
                        <div
                          className="progress-animate"
                          data-duration="3000"
                        ></div>
                      </div>
                    </div>
                    <div className="progress-item">
                      <div className="name">Long term warranty</div>
                      <div className="perc show">
                        <span>60%</span>
                      </div>
                      <div
                        className="progres-bar"
                        data-percent="60"
                        data-inviewport="yes"
                      >
                        <div
                          className="progress-animate"
                          data-duration="3000"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flat-spacer clearfix"
                data-desktop="47"
                data-mobile="47"
                data-smobile="47"
              ></div>
            </div>
          </div>
        </section>
      </div>

      <div className="flat-client">
        <div className="container">
          <div
            className="flat-spacer clearfix"
            data-desktop="80"
            data-mobile="60"
            data-smobile="60"
          ></div>
          <div
            className="flat-carousel-not-numb"
            data-auto="true"
            data-column="5"
            data-column2="3"
            data-column3="2"
            data-gap=""
            data-dots="false"
            data-nav="false"
            data-loop="true"
          >
            <div className="client carousel-service owl-carousel owl-theme owl-loaded">
              <div className="client-item">
                <a href="#">
                  <img src="/assets/images/home1/client-1.png" alt="AutoMov" />
                  <img
                    src="/assets/images/home1/client-1-hover.png"
                    alt="AutoMov"
                  />
                </a>
              </div>
              <div className="client-item">
                <a href="#">
                  <img src="/assets/images/home1/client-2.png" alt="AutoMov" />
                  <img
                    src="/assets/images/home1/client-2-hover.png"
                    alt="AutoMov"
                  />
                </a>
              </div>
              <div className="client-item">
                <a href="#">
                  <img src="/assets/images/home1/client-3.png" alt="AutoMov" />
                  <img
                    src="/assets/images/home1/client-3-hover.png"
                    alt="AutoMov"
                  />
                </a>
              </div>
              <div className="client-item">
                <a href="#">
                  <img src="/assets/images/home1/client-4.png" alt="AutoMov" />
                  <img
                    src="/assets/images/home1/client-4-hover.png"
                    alt="AutoMov"
                  />
                </a>
              </div>
              <div className="client-item">
                <a href="#">
                  <img src="/assets/images/home1/client-5.png" alt="AutoMov" />
                  <img
                    src="/assets/images/home1/client-5-hover.png"
                    alt="AutoMov"
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            className="flat-spacer clearfix"
            data-desktop="80"
            data-mobile="60"
            data-smobile="60"
          ></div>
        </div>
      </div>

      <section className="flat-contact v1 clearfix">
        <div className="container">
          <div className="wrap-text">
            <h3 className="title">
              <a href="#">
                Have you question or need any help for work consulant?
              </a>
            </h3>
          </div>
          <div className="wrap-btn text-right">
            <a href="/appointment" className="flat-button btn-contact bg-contact">
              Contact us
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurTeam;

import React from "react";

const Categories = () => {
  return (
    <section className="flat-categories-car">
      <div className="container">
        <div
          className="flat-spacer clearfix"
          data-desktop="80"
          data-mobile="60"
          data-smobile="60"
        ></div>
        <div className="flat-title text-center">
          <h2>ALL CATEGORIES CAR</h2>
          <p>
            
          </p>
        </div>
        <div
          className="flat-spacer clearfix"
          data-desktop="66"
          data-mobile="40"
          data-smobile="40"
        ></div>
        <div className="flat-imgbox clearfix isotope-project">
          <div className="imgbox one-of-four">
            <div className="imgbox-img">
              <img src="/assets/images/home5/category-1.png" alt="AutoMov" />
              <div className="overlay"></div>
            </div>
            <div className="imgbox-content text-center">
              <h4>
                <a href="/shop">Wheels</a>
              </h4>
            </div>
          </div>

          <div className="imgbox one-of-four">
            <div className="imgbox-img">
              <img src="/assets/images/home5/category-2.png" alt="AutoMov" />
              <div className="overlay"></div>
            </div>
            <div className="imgbox-content text-center">
              <h4>
                <a href="/shop">Lighting</a>
              </h4>
            </div>
          </div>

          <div className="imgbox one-of-four">
            <div className="imgbox-img">
              <img src="/assets/images/home5/category-3.png" alt="AutoMov" />
              <div className="overlay"></div>
            </div>
            <div className="imgbox-content text-center">
              <h4>
                <a href="/shop">Performance</a>
              </h4>
            </div>
          </div>

          <div className="imgbox one-of-four">
            <div className="imgbox-img">
              <img src="/assets/images/home5/category-4.png" alt="AutoMov" />
              <div className="overlay"></div>
            </div>
            <div className="imgbox-content text-center">
              <h4>
                <a href="/shop">Electronic</a>
              </h4>
            </div>
          </div>

          <div className="imgbox one-of-four">
            <div className="imgbox-img">
              <img src="/assets/images/home5/category-5.png" alt="AutoMov" />
              <div className="overlay"></div>
            </div>
            <div className="imgbox-content text-center">
              <h4>
                <a href="/shop">Body parts</a>
              </h4>
            </div>
          </div>

          <div className="imgbox one-of-four">
            <div className="imgbox-img">
              <img src="/assets/images/home5/category-6.png" alt="AutoMov" />
              <div className="overlay"></div>
            </div>
            <div className="imgbox-content text-center">
              <h4>
                <a href="/shop">Interior</a>
              </h4>
            </div>
          </div>

          <div className="imgbox one-of-four">
            <div className="imgbox-img">
              <img src="/assets/images/home5/category-7.png" alt="AutoMov" />
              <div className="overlay"></div>
            </div>
            <div className="imgbox-content text-center">
              <h4>
                <a href="/shop">Vertical doors</a>
              </h4>
            </div>
          </div>

          <div className="imgbox one-of-four">
            <div className="imgbox-img">
              <img src="/assets/images/home5/category-8.png" alt="AutoMov" />
              <div className="overlay"></div>
            </div>
            <div className="imgbox-content text-center">
              <h4>
                <a href="/shop">Repair parts</a>
              </h4>
            </div>
          </div>
        </div>
        <div className="wrap-btn text-center">
          <a href="/shop" className="flat-button btn-radius">
            ALL VEHICLES
          </a>
        </div>
        <div
          className="flat-spacer clearfix"
          data-desktop="96"
          data-mobile="60"
          data-smobile="60"
        ></div>
      </div>
    </section>
  );
};

export default Categories;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config.json';
import './DetailPage.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, addToCart } from '../../redux/actions/ShopActions';
import DropdownMenu from '../../components/dropdown/dropdown';
const apiKey  = config.apiKeys;
const DetailPage = () => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.shop.products);
  const [isHovered, setIsHovered] = useState(false);
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cartNumber, setCartNumber] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [review, setReview] = useState("");
  const [reviews, setReviews] = useState([]);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://backend.fredautorepair.com/cart/products/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
          },
        });
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Error fetching product. Please try again later.');
        setLoading(false);
      }
    };

    const fetchReviews = async () => {
      try {
        const response = await axios.get(`https://backend.fredautorepair.com/reviews/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
          },
        });
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchProduct();
    fetchReviews();
  }, [id]);

  // const addToCart = async (productId, quantity) => {
  //   const userId = localStorage.getItem("id");

  //   if (!userId) {
  //     alert("Please log in to add items to your cart.");
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       `https://backend.fredautorepair.com/cart/${userId}/items`,
  //       {
  //         productId,
  //         quantity,
  //       },
  //       {
  //         headers: {
  //           "x-api-key": apiKey,
  //         },
  //       }
  //     );

  //     setCartItems(response.data.items);
  //     setCartNumber(response.data.id);
  //     localStorage.setItem('cartItems', JSON.stringify(response.data.items));
  //     localStorage.setItem('cartId', JSON.stringify(response.data.id));
  //     console.log("Product added to cart successfully");
  //   } catch (error) {
  //     console.error("Error adding product to cart:", error);
  //   }
  // };

  const toggleReviewForm = () => {
    setShowReviewForm(!showReviewForm);
  };

  const submitReview = async () => {
    const newReview = {
      userName: userName, 
      email: userEmail,
      text: review,
    };

    try {
      const response = await axios.post(
        `https://backend.fredautorepair.com/review/GarageReviews`,
        {
          productId: id,
          ...newReview,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
          },
        }
      );

      setReviews([...reviews, response.data]);
      setReview("");
      setUserName(""); 
      setUserEmail(""); 
      setShowReviewForm(false); 
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>

    <div className="product-detail-container">
      <header className="product-detail-header">
        <h1>Product Detail Page</h1>
      </header>
      <main className="product-detail-main">
     
        <div className="welcome-message">
          <h2>Detail Page</h2>
        </div>
        <div className="product-info">
          {product ? (
            <>
              <div className="product-image-wrapper"
                   onMouseEnter={() => setIsHovered(true)}
                   onMouseLeave={() => setIsHovered(false)}>
                <div className="product-image">
                  <img src={product.img} alt={product.name} style={{ transform: isHovered ? 'scale(1.5)' : 'scale(1)', transition: 'transform 0.3s ease' }} />
                  <i className="fas fa-search-plus magnify-icon"></i>
                </div>
              </div>
              <div className="product-description">
                <h3>{product.name}</h3>
                <p>{product.ldescription}</p>
                <p>Price: ${product.price}</p>
              </div>
            </>
          ) : (
            <p>Product not found.</p>
          )}
        </div>
        <div className="add-to-cart-container">
          <button onClick={() => dispatch(addToCart(product.id, 1))}>Add to Cart</button>
        </div>
        <div className="reviews-container">
          <h3>Reviews:</h3>
          {reviews.length > 0 ? (
            <ul>
              {reviews.map((review) => (
                <li key={review.id}>
                  <strong>{review.userName}</strong>: {review.text}
                </li>
              ))}
            </ul>
          ) : (
            <p>No reviews yet.</p>
          )}
        </div>
        <div className="review-form-container">
          {!showReviewForm && (
            <button onClick={toggleReviewForm}>Write Review</button>
          )}
          {showReviewForm && (
            <>
              <h3>Leave a Review</h3>
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Your Name"
              />
              <input
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Your Email"
              />
              <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                placeholder="Write your review here"
              />
              <button className="buttonflex" onClick={submitReview}>Submit Review</button>
            </>
          )}
        </div>
      </main>
    </div>
    </>
  );
};

export default DetailPage;

// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { initGA, logPageView } from './pages/analytics';
import 'dotenv/config';
import UserHome from './layout/UserHome'; // Import UserHome component from its source file


const App = () => {

 
  
  const history = useHistory();




 
  useEffect(() => {
    initGA('G-Q0MBKMDC32'); // Replace with your Google Analytics tracking ID
    logPageView();
    const unlisten = history.listen(() => {
      logPageView();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <div>
      <UserHome />
      
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;

import React, { useState } from 'react';
import './registerForm.css';
import config from '../../config.json';


export const Register = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
 
  const apikey =  config.apiKeys;
  const handleRegister = async () => {
    try {
      const response = await fetch('https://backend.fredautorepair.com/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apikey,
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        throw new Error('Registration failed');
      }
  
      const data = await response.json();
      localStorage.setItem('id', data.id);
      localStorage.setItem('accessToken', data.accessToken);  // Save accessToken if needed
  
    //   onLogin(data);  // Call the onLogin prop
      window.location.href = '/login';
    } catch (error) {
      console.error('Error registering:', error);
      setError('Error registering. Please try again later.');
    }
  };

  return (
    <div className="login-container">
      <h2>Register Form</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleRegister(); }}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit" className="login-button">Register</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default Register;

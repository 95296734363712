import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import StarRating from './StarRating'; // Assuming you have a StarRating component
import './productDetail.css'; // Assuming you have some CSS file for styling
import config from '../../config.json';

const apiKey = config.apiKeys;
const ProductDetail = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [review, setReview] = useState([]);
  const [rating, setRating] = useState(0);
  // Remove unused variables and functions
  // const [user, setUser] = useState(0);
  const [cartNumber, setCartNumber] = useState("");
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://backend.fredautorepair.com/cart/products/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            "x-api-key": apiKey,
          },
        });
       
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Error fetching product. Please try again later.');
      }
    };


    

    const fetchReviews = async () => {
      try {
        const response = await axios.get(`https://backend.fredautorepair.com/review`, {
          headers: {
            'Content-Type': 'application/json',
            "x-api-key": apiKey,
          },
        });
      console.log(response.data)
        setReviews(response.data);
        // setUser(response.data); // Commented out as it is unused
        
        const gg = reviews.filter(user => user.userId === id);
        console.log(gg)
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchProduct();
    fetchReviews();
  }, );

  const handleReviewSubmit = async () => {
  
    try {
      const response = await axios.post('https://backend.fredautorepair.com/reviews', {
        productId: id,
        review,
        rating,
      }, {
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": apiKey,
        },
      });
      
      // Clear the form and update reviews
      setReview('');
      setRating(0);
      // Fetch reviews again to update the list
      
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };
  const addToCart = async (productId, quantity) => {
    const userId = localStorage.getItem("id");

    if (!userId) {
      alert("Please log in to add items to your cart.");
      return;
    }

    try {
      const response = await axios.post(
        `https://backend.fredautorepair.com/cart/${userId}/items`,
        {
          productId,
          quantity,
        },
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );

      setCartItems(response.data.items);
      setCartNumber(response.data.id);
      localStorage.setItem('cartItems', JSON.stringify(response.data.items));
      localStorage.setItem('cartId', JSON.stringify(response.data.id));
      console.log("Product added to cart successfully");
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  const handleAddToCart = async () => {
    try {
      const response = await axios.post('https://backend.fredautorepair.com/cart/add', {
        productId: id,
        quantity: 1, // You can change this to the desired quantity
      }, {
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": apiKey,
        },
      });
      console.log('Product added to cart:', response.data);
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!product) {
    return <p>Loading...</p>;
  }

  return (
    <>
    <div className="product-detail">
       <div className="product-name"></div>
      <p>{product.name}</p>
      <div className="product-info">
        <img src={product.img} alt={product.name} />
        <div className="product-description">
          <p>{product.description}</p>
             <div className="product-price">
          <p>Price: ${product.price}</p>
          <div className="product-detailed-description">
            <h3>Detailed Description</h3>
            <p>{product.ldescription}</p>
          </div >
          <button  className="product-detailed-description" onClick={() => addToCart(product.id, 1)}>Add to Cart</button>
        </div>
        </div>
      </div>
      <div className="additional-container">
        <h3>Additional Container</h3>
        <p>{product.ldescription}</p>
      </div>
      <div className="additional-details">
        <h3>Additional Details</h3>
        <p>{product.ldescription}</p>
      </div>
      <div className="currency-converter">
        <h4>Currency Converter</h4>
        <p>Include a currency converter here...</p>
      </div>
      <div className="product-reviews">
        <h3>Customer Reviews</h3>
        {reviews.length === 0 && <p>No reviews yet.</p>}
        {reviews.map((review, index) => (
          <div key={index} className="product-review">
            <p>{review.content}</p>
            <StarRating rating={review.rating} />
          </div>
        ))}
      </div>
      <div className="product-review-form">
        <h3>Leave a Review</h3>
        <form onSubmit={handleReviewSubmit}>
          <textarea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Write your review here"
            required
          />
          <div>
            <label>
              Rating:
              <StarRating rating={rating} onRatingChange={(value) => setRating(value)} />
            </label>
          </div>
          <button type="submit">Submit Review</button>
        </form>
      </div>
    </div>
    </>
  );
};

export default ProductDetail;

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
import chatReducer from './reducers/ChatReducer';
import cartReducer from './reducers/cartReducer';
import serviceReducer from './reducers/serviceReducer';
import appointmentReducer from './reducers/appointmentReducer';
import productReducer from './reducers/productReducer';
import authReducer from './reducers/authReducer';
import shopReducer from './reducers/ShopReducer';

// Combine all reducers into a rootReducer
const rootReducer = combineReducers({
  chat: chatReducer,
  cart: cartReducer,
  service: serviceReducer,
  appointment: appointmentReducer,
  product: productReducer,
  auth: authReducer,
  shop:shopReducer
  // Add other reducers here
});

const store = createStore(
  rootReducer,
  // composeWithDevTools(applyMiddleware(thunk)) // Use this line if you want to integrate Redux DevTools
  applyMiddleware(thunk)
);

export default store;
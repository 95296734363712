import userReducer from "./UserReducer";
import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import ServiceReducer from "./serviceReducer";
import AppointmentReducer from './appointmentReducer';
import productReducer from "./productReducer";
import authReducer from "./authReducer";
import chatReducer from "./ChatReducer";
const rootReducer = combineReducers({
  // userReducer,
  // cartReducer,
  // ServiceReducer,
  // AppointmentReducer,
  // productReducer,
  // authReducer,
  chatReducer
  // write all imported reducers here
});

export default rootReducer;

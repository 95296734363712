import React, { useState } from "react";
import "./dropdown.css";
import axios from "axios";
import config from '../../config.json';
import CarDetails from '../CarDetails/CarDetails';
export const DropdownMenu = () => {
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [selecteManufacturer, setSelecteManufacturer] = useState("");
    const [selecteBody, setSelecteBody] = useState("");
    const [selecteEngine, setSelecteEngine] = useState("");
    const [selectedCar, setSelectedCar] = useState("");
    const years = [2001, 2002, 2003, 2004, 2005];
    const  apiKey  = config.apiKeys;
    const models = ["ford", "toyota", "honda", "nissan", "bmw"];
    const body_styles = ["coupe", "sedan", "hatchback", "pickup", "van","convertible"];
    const engine = ["4cyl", "v6", "v8"];
    const [filteredCars, setFilteredCars] = useState([]);

    const car = [
        {
          "id": 1,  
          "year": 2020,
          "manufacturer": "Toyota",
          "model": "Camry",
          "engine": "2.5L 4-Cylinder",
          "body_style": "Sedan"
        },
        {
            "id": 2,
          "year": 2018,
          "manufacturer": "Ford",
          "model": "Mustang",
          "engine": "5.0L V8",
          "body_style": "Coupe"
        },
        {
            "id": 3,
          "year": 2021,
          "manufacturer": "Tesla",
          "model": "Model 3",
          "engine": "Electric",
          "body_style": "Sedan"
        },
        {
            "id": 4,
          "year": 2019,
          "manufacturer": "Chevrolet",
          "model": "Silverado",
          "engine": "6.2L V8",
          "body_style": "Truck"
        },
        {
            "id": 5,
          "year": 2022,
          "manufacturer": "Honda",
          "model": "Civic",
          "engine": "2.0L 4-Cylinder",
          "body_style": "Sedan"
        },
        {
            "id": 6,
          "year": 2017,
          "manufacturer": "BMW",
          "model": "3 Series",
          "engine": "2.0L Turbocharged I4",
          "body_style": "Sedan"
        },
        {
            "id": 7,
          "year": 2023,
          "manufacturer": "Audi",
          "model": "A4",
          "engine": "2.0L Turbocharged I4",
          "body_style": "Sedan"
        },
        {
            "id": 8,
          "year": 2015,
          "manufacturer": "Jeep",
          "model": "Wrangler",
          "engine": "3.6L V6",
          "body_style": "SUV"
        },
        {
            "id": 9,
          "year": 2016,
          "manufacturer": "Mercedes-Benz",
          "model": "C-Class",
          "engine": "2.0L Turbocharged I4",
          "body_style": "Sedan"
        },
        {
            "id": 10,
          "year": 2020,
          "manufacturer": "Porsche",
          "model": "911",
          "engine": "3.0L Twin-Turbo H6",
          "body_style": "Coupe"
        },
        {
            "id": 2,
            "year": 2019,
            "manufacturer": "Chevrolet",
            "model": "Camaro",
            "engine": "6.2L V8",
            "body_style": "Coupe"
        },
        {
            "id": 3,
            "year": 2020,
            "manufacturer": "Toyota",
            "model": "Corolla",
            "engine": "1.8L I4",
            "body_style": "Sedan"
        },
        {
            "id": 4,
            "year": 2021,
            "manufacturer": "Honda",
            "model": "Civic",
            "engine": "2.0L I4",
            "body_style": "Sedan"
        },
        {
            "id": 5,
            "year": 2017,
            "manufacturer": "BMW",
            "model": "3 Series",
            "engine": "2.0L I4",
            "body_style": "Sedan"
        },
        {
            "id": 6,
            "year": 2016,
            "manufacturer": "Audi",
            "model": "A4",
            "engine": "2.0L I4",
            "body_style": "Sedan"
        },
        {
            "id": 7,
            "year": 2015,
            "manufacturer": "Mercedes-Benz",
            "model": "C-Class",
            "engine": "2.0L I4",
            "body_style": "Sedan"
        },
        {
            "id": 8,
            "year": 2014,
            "manufacturer": "Nissan",
            "model": "Altima",
            "engine": "2.5L I4",
            "body_style": "Sedan"
        },
        {
            "id": 9,
            "year": 2013,
            "manufacturer": "Hyundai",
            "model": "Elantra",
            "engine": "1.8L I4",
            "body_style": "Sedan"
        },
        {
            "id": 10,
            "year": 2022,
            "manufacturer": "Tesla",
            "model": "Model 3",
            "engine": "Electric",
            "body_style": "Sedan"
        },
        {
            "id": 11,
            "year": 2021,
            "manufacturer": "Ford",
            "model": "F-150",
            "engine": "3.5L V6",
            "body_style": "Truck"
        },
        {
            "id": 12,
            "year": 2020,
            "manufacturer": "Ram",
            "model": "1500",
            "engine": "5.7L V8",
            "body_style": "Truck"
        },
        {
            "id": 13,
            "year": 2019,
            "manufacturer": "Jeep",
            "model": "Wrangler",
            "engine": "3.6L V6",
            "body_style": "SUV"
        },
        {
            "id": 14,
            "year": 2018,
            "manufacturer": "Subaru",
            "model": "Outback",
            "engine": "2.5L H4",
            "body_style": "SUV"
        },
        {
            "id": 15,
            "year": 2017,
            "manufacturer": "Mazda",
            "model": "CX-5",
            "engine": "2.5L I4",
            "body_style": "SUV"
        },
        {
            "id": 16,
            "year": 2016,
            "manufacturer": "Volkswagen",
            "model": "Golf",
            "engine": "1.8L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 17,
            "year": 2015,
            "manufacturer": "Kia",
            "model": "Soul",
            "engine": "2.0L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 18,
            "year": 2014,
            "manufacturer": "Porsche",
            "model": "911",
            "engine": "3.8L H6",
            "body_style": "Coupe"
        },
        {
            "id": 19,
            "year": 2013,
            "manufacturer": "Ferrari",
            "model": "458 Italia",
            "engine": "4.5L V8",
            "body_style": "Coupe"
        },
        {
            "id": 20,
            "year": 2012,
            "manufacturer": "Lamborghini",
            "model": "Aventador",
            "engine": "6.5L V12",
            "body_style": "Coupe"
        },
        {
            "id": 21,
            "year": 2011,
            "manufacturer": "Bentley",
            "model": "Continental GT",
            "engine": "6.0L W12",
            "body_style": "Coupe"
        },
        {
            "id": 22,
            "year": 2010,
            "manufacturer": "Rolls-Royce",
            "model": "Ghost",
            "engine": "6.6L V12",
            "body_style": "Sedan"
        },
        {
            "id": 23,
            "year": 2009,
            "manufacturer": "Jaguar",
            "model": "XF",
            "engine": "5.0L V8",
            "body_style": "Sedan"
        },
        {
            "id": 24,
            "year": 2008,
            "manufacturer": "Maserati",
            "model": "Quattroporte",
            "engine": "4.2L V8",
            "body_style": "Sedan"
        },
        {
            "id": 25,
            "year": 2007,
            "manufacturer": "Aston Martin",
            "model": "DB9",
            "engine": "5.9L V12",
            "body_style": "Coupe"
        },{
            "id": 26,
            "year": 2006,
            "manufacturer": "Lexus",
            "model": "RX",
            "engine": "3.5L V6",
            "body_style": "SUV"
        },
        {
            "id": 27,
            "year": 2005,
            "manufacturer": "Infiniti",
            "model": "G35",
            "engine": "3.5L V6",
            "body_style": "Sedan"
        },
        {
            "id": 28,
            "year": 2004,
            "manufacturer": "Cadillac",
            "model": "CTS",
            "engine": "3.6L V6",
            "body_style": "Sedan"
        },
        {
            "id": 29,
            "year": 2003,
            "manufacturer": "Lincoln",
            "model": "Navigator",
            "engine": "5.4L V8",
            "body_style": "SUV"
        },
        {
            "id": 30,
            "year": 2002,
            "manufacturer": "Acura",
            "model": "MDX",
            "engine": "3.5L V6",
            "body_style": "SUV"
        },
        {
            "id": 31,
            "year": 2001,
            "manufacturer": "Chrysler",
            "model": "300M",
            "engine": "3.5L V6",
            "body_style": "Sedan"
        },
        {
            "id": 32,
            "year": 2000,
            "manufacturer": "Buick",
            "model": "LeSabre",
            "engine": "3.8L V6",
            "body_style": "Sedan"
        },
        {
            "id": 33,
            "year": 1999,
            "manufacturer": "Pontiac",
            "model": "Grand Prix",
            "engine": "3.8L V6",
            "body_style": "Sedan"
        },
        {
            "id": 34,
            "year": 1998,
            "manufacturer": "Oldsmobile",
            "model": "Intrigue",
            "engine": "3.5L V6",
            "body_style": "Sedan"
        },
        {
            "id": 35,
            "year": 1997,
            "manufacturer": "Plymouth",
            "model": "Voyager",
            "engine": "3.3L V6",
            "body_style": "Minivan"
        },
        {
            "id": 36,
            "year": 1996,
            "manufacturer": "Geo",
            "model": "Metro",
            "engine": "1.3L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 37,
            "year": 1995,
            "manufacturer": "Saturn",
            "model": "S-Series",
            "engine": "1.9L I4",
            "body_style": "Sedan"
        },
        {
            "id": 38,
            "year": 1994,
            "manufacturer": "Suzuki",
            "model": "Sidekick",
            "engine": "1.6L I4",
            "body_style": "SUV"
        },
        {
            "id": 39,
            "year": 1993,
            "manufacturer": "Isuzu",
            "model": "Rodeo",
            "engine": "3.2L V6",
            "body_style": "SUV"
        },
        {
            "id": 40,
            "year": 1992,
            "manufacturer": "Daewoo",
            "model": "Lanos",
            "engine": "1.6L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 41,
            "year": 1991,
            "manufacturer": "Eagle",
            "model": "Talon",
            "engine": "2.0L I4",
            "body_style": "Coupe"
        },
        {
            "id": 42,
            "year": 1990,
            "manufacturer": "Yugo",
            "model": "GV",
            "engine": "1.1L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 43,
            "year": 1989,
            "manufacturer": "Peugeot",
            "model": "405",
            "engine": "1.9L I4",
            "body_style": "Sedan"
        },
        {
            "id": 44,
            "year": 1988,
            "manufacturer": "Renault",
            "model": "Alliance",
            "engine": "1.7L I4",
            "body_style": "Sedan"
        },
        {
            "id": 45,
            "year": 1987,
            "manufacturer": "Alfa Romeo",
            "model": "Spider",
            "engine": "2.0L I4",
            "body_style": "Convertible"
        },
        {
            "id": 46,
            "year": 1986,
            "manufacturer": "Lancia",
            "model": "Delta",
            "engine": "1.6L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 47,
            "year": 1985,
            "manufacturer": "Fiat",
            "model": "X1/9",
            "engine": "1.5L I4",
            "body_style": "Coupe"
        },
        {
            "id": 48,
            "year": 1984,
            "manufacturer": "MG",
            "model": "Midget",
            "engine": "1.5L I4",
            "body_style": "Convertible"
        },
        {
            "id": 49,
            "year": 1983,
            "manufacturer": "Triumph",
            "model": "TR7",
            "engine": "2.0L I4",
            "body_style": "Convertible"
        },
        {
            "id": 50,
            "year": 1982,
            "manufacturer": "Austin",
            "model": "Mini",
            "engine": "1.3L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 51,
            "year": 1981,
            "manufacturer": "Citroën",
            "model": "2CV",
            "engine": "0.6L I2",
            "body_style": "Convertible"
        },
        {
            "id": 52,
            "year": 1980,
            "manufacturer": "Saab",
            "model": "900",
            "engine": "2.0L I4",
            "body_style": "Sedan"
        },
        {
            "id": 53,
            "year": 1979,
            "manufacturer": "Volvo",
            "model": "240",
            "engine": "2.1L I4",
            "body_style": "Sedan"
        },
        {
            "id": 54,
            "year": 1978,
            "manufacturer": "Opel",
            "model": "Manta",
            "engine": "1.9L I4",
            "body_style": "Coupe"
        },
        {
            "id": 55,
            "year": 1977,
            "manufacturer": "Vauxhall",
            "model": "Viva",
            "engine": "1.3L I4",
            "body_style": "Sedan"
        },
        {
            "id": 56,
            "year": 1976,
            "manufacturer": "Datsun",
            "model": "280Z",
            "engine": "2.8L I6",
            "body_style": "Coupe"
        },
        {
            "id": 57,
            "year": 1975,
            "manufacturer": "Mazda",
            "model": "RX-3",
            "engine": "1.1L Wankel",
            "body_style": "Coupe"
        },
        {
            "id": 58,
            "year": 1974,
            "manufacturer": "Subaru",
            "model": "GL",
            "engine": "1.4L I4",
            "body_style": "Sedan"
        },
        {
            "id": 59,
            "year": 1973,
            "manufacturer": "Holden",
            "model": "Monaro",
            "engine": "5.0L V8",
            "body_style": "Coupe"
        },
        {
            "id": 60,
            "year": 1972,
            "manufacturer": "Ford",
            "model": "Pinto",
            "engine": "2.0L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 61,
            "year": 1971,
            "manufacturer": "Chevrolet",
            "model": "Vega",
            "engine": "2.3L I4",
            "body_style": "Hatchback"
        },
        {
            "id": 62,
            "year": 1970,
            "manufacturer": "AMC",
            "model": "Gremlin",
            "engine": "3.8L I6",
            "body_style": "Hatchback"
        },
        {
            "id": 63,
            "year": 1969,
            "manufacturer": "Pontiac",
            "model": "GTO",
            "engine": "6.6L V8",
            "body_style": "Coupe"
        },
        {
            "id": 64,
            "year": 1968,
            "manufacturer": "Dodge",
            "model": "Charger",
            "engine": "7.2L V8",
            "body_style": "Coupe"
        },
        {
            "id": 65,
            "year": 1967,
            "manufacturer": "Buick",
            "model": "Skylark",
            "engine": "5.7L V8",
            "body_style": "Coupe"
        },
        {
            "id": 66,
            "year": 1966,
            "manufacturer": "Chevrolet",
            "model": "Corvette",
            "engine": "5.3L V8",
            "body_style": "Coupe"
        },
        {
            "id": 67,
            "year": 1965,
            "manufacturer": "Ford",
            "model": "Mustang",
            "engine": "4.7L V8",
            "body_style": "Coupe"
        },
        {
            "id": 68,
            "year": 1964,
            "manufacturer": "Plymouth",
            "model": "Barracuda",
            "engine": "4.5L V8",
            "body_style": "Coupe"
        },
        {
            "id": 69,
            "year": 1963,
            "manufacturer": "Studebaker",
            "model": "Avanti",
            "engine": "4.7L V8",
            "body_style": "Coupe"
        },
        {
            "id": 70,
            "year": 1962,
            "manufacturer": "Chrysler",
            "model": "300",
            "engine": "6.8L V8",
            "body_style": "Sedan"
        },
        {
            "id": 71,
            "year": 1961,
            "manufacturer": "Lincoln",
            "model": "Continental",
            "engine": "7.0L V8",
            "body_style": "Sedan"
        },
        {
            "id": 72,
            "year": 1960,
            "manufacturer": "Cadillac",
            "model": "DeVille",
            "engine": "6.4L V8",
            "body_style": "Sedan"
        },
        {
            "id": 73,
            "year": 1959,
            "manufacturer": "Mercury",
            "model": "Montclair",
            "engine": "7.0L V8",
            "body_style": "Sedan"
        },
        {
            "id": 74,
            "year": 1958,
            "manufacturer": "Packard",
            "model": "Patrician",
            "engine": "6.1L V8",
            "body_style": "Sedan"
        },
        {
            "id": 75,
            "year": 1957,
            "manufacturer": "Hudson",
            "model": "Hornet",
            "engine": "5.0L I6",
            "body_style": "Sedan"
        },    {
            "id": 76,
            "year": 1956,
            "manufacturer": "DeSoto",
            "model": "Firedome",
            "engine": "5.4L V8",
            "body_style": "Sedan"
        },
        {
            "id": 77,
            "year": 1955,
            "manufacturer": "Nash",
            "model": "Ambassador",
            "engine": "4.3L I6",
            "body_style": "Sedan"
        },
        {
            "id": 78,
            "year": 1954,
            "manufacturer": "Kaiser",
            "model": "Darrin",
            "engine": "2.6L I6",
            "body_style": "Convertible"
        },
        {
            "id": 79,
            "year": 1953,
            "manufacturer": "Studebaker",
            "model": "Commander",
            "engine": "3.8L V8",
            "body_style": "Sedan"
        },
        {
            "id": 80,
            "year": 1952,
            "manufacturer": "Willys",
            "model": "Aero",
            "engine": "2.2L I6",
            "body_style": "Sedan"
        },
        {
            "id": 81,
            "year": 1951,
            "manufacturer": "Frazer",
            "model": "Manhattan",
            "engine": "3.7L I6",
            "body_style": "Sedan"
        },
        {
            "id": 82,
            "year": 1950,
            "manufacturer": "Crosley",
            "model": "Hotshot",
            "engine": "0.7L I4",
            "body_style": "Convertible"
        },
        {
            "id": 83,
            "year": 1949,
            "manufacturer": "Tucker",
            "model": "48",
            "engine": "5.5L H6",
            "body_style": "Sedan"
        },
        {
            "id": 84,
            "year": 1948,
            "manufacturer": "Hudson",
            "model": "Commodore",
            "engine": "4.3L I6",
            "body_style": "Sedan"
        },
        {
            "id": 85,
            "year": 1947,
            "manufacturer": "Packard",
            "model": "Clipper",
            "engine": "4.6L I8",
            "body_style": "Sedan"
        },
        {
            "id": 86,
            "year": 1946,
            "manufacturer": "Chevrolet",
            "model": "Fleetmaster",
            "engine": "3.5L I6",
            "body_style": "Sedan"
        },
        {
            "id": 87,
            "year": 1945,
            "manufacturer": "Ford",
            "model": "Super Deluxe",
            "engine": "3.9L V8",
            "body_style": "Sedan"
        },
        {
            "id": 88,
            "year": 1944,
            "manufacturer": "Buick",
            "model": "Special",
            "engine": "4.1L I8",
            "body_style": "Sedan"
        },
        {
            "id": 89,
            "year": 1943,
            "manufacturer": "Chrysler",
            "model": "Royal",
            "engine": "4.1L I6",
            "body_style": "Sedan"
        },
        {
            "id": 90,
            "year": 1942,
            "manufacturer": "Dodge",
            "model": "Custom",
            "engine": "3.8L I6",
            "body_style": "Sedan"
        },
        {
            "id": 91,
            "year": 1941,
            "manufacturer": "Oldsmobile",
            "model": "Dynamic",
            "engine": "3.9L I6",
            "body_style": "Sedan"
        },
        {
            "id": 92,
            "year": 1940,
            "manufacturer": "Plymouth",
            "model": "Deluxe",
            "engine": "3.3L I6",
            "body_style": "Sedan"
        },
        {
            "id": 93,
            "year": 1939,
            "manufacturer": "Mercury",
            "model": "Eight",
            "engine": "3.9L V8",
            "body_style": "Sedan"
        },
        {
            "id": 94,
            "year": 1938,
            "manufacturer": "Lincoln",
            "model": "Zephyr",
            "engine": "4.4L V12",
            "body_style": "Sedan"
        },
        {
            "id": 95,
            "year": 1937,
            "manufacturer": "Cadillac",
            "model": "Series 60",
            "engine": "5.7L V8",
            "body_style": "Sedan"
        },
        {
            "id": 96,
            "year": 1936,
            "manufacturer": "Buick",
            "model": "Roadmaster",
            "engine": "5.2L I8",
            "body_style": "Sedan"
        },
        {
            "id": 97,
            "year": 1935,
            "manufacturer": "Ford",
            "model": "Model 48",
            "engine": "3.6L V8",
            "body_style": "Sedan"
        },
        {
            "id": 98,
            "year": 1934,
            "manufacturer": "Chevrolet",
            "model": "Master",
            "engine": "3.4L I6",
            "body_style": "Sedan"
        },
        {
            "id": 99,
            "year": 1933,
            "manufacturer": "Plymouth",
            "model": "PC",
            "engine": "3.3L I6",
            "body_style": "Sedan"
        },
        {
            "id": 100,
            "year": 1932,
            "manufacturer": "Chrysler",
            "model": "Six",
            "engine": "3.6L I6",
            "body_style": "Sedan"
        },
        {
            "id": 101,
            "year": 1931,
            "manufacturer": "Pontiac",
            "model": "Big Six",
            "engine": "3.6L I6",
            "body_style": "Sedan"
        },
        {
            "id": 102,
            "year": 1930,
            "manufacturer": "Nash",
            "model": "Standard Six",
            "engine": "3.2L I6",
            "body_style": "Sedan"
        },
        {
            "id": 103,
            "year": 1929,
            "manufacturer": "Dodge",
            "model": "DA",
            "engine": "3.6L I6",
            "body_style": "Sedan"
        },
        {
            "id": 104,
            "year": 1928,
            "manufacturer": "Ford",
            "model": "Model A",
            "engine": "3.3L I4",
            "body_style": "Sedan"
        },
        {
            "id": 105,
            "year": 1927,
            "manufacturer": "Chevrolet",
            "model": "Series AA Capitol",
            "engine": "2.8L I4",
            "body_style": "Sedan"
        },
        {
            "id": 106,
            "year": 1926,
            "manufacturer": "Buick",
            "model": "Master Six",
            "engine": "4.5L I6",
            "body_style": "Sedan"
        },
        {
            "id": 107,
            "year": 1925,
            "manufacturer": "Hudson",
            "model": "Super Six",
            "engine": "4.8L I6",
            "body_style": "Sedan"
        },
        {
            "id": 108,
            "year": 1924,
            "manufacturer": "Chrysler",
            "model": "B-70",
            "engine": "3.3L I4",
            "body_style": "Sedan"
        },
        {
            "id": 109,
            "year": 1923,
            "manufacturer": "Studebaker",
            "model": "Light Six",
            "engine": "3.0L I6",
            "body_style": "Sedan"
        },
        {
            "id": 110,
            "year": 1922,
            "manufacturer": "Lincoln",
            "model": "Model L",
            "engine": "5.9L V8",
            "body_style": "Sedan"
        },
        {
            "id": 111,
            "year": 1921,
            "manufacturer": "Willys",
            "model": "Knight",
            "engine": "3.6L I4",
            "body_style": "Sedan"
        },
        {
            "id": 112,
            "year": 1920,
            "manufacturer": "Dodge",
            "model": "30-35",
            "engine": "3.5L I4",
            "body_style": "Sedan"
        },
        {
            "id": 113,
            "year": 1919,
            "manufacturer": "Chevrolet",
            "model": "490",
            "engine": "2.8L I4",
            "body_style": "Sedan"
        },
        {
            "id": 114,
            "year": 1918,
            "manufacturer": "Cadillac",
            "model": "Type 57",
            "engine": "5.2L V8",
            "body_style": "Sedan"
        },
        {
            "id": 115,
            "year": 1917,
            "manufacturer": "Buick",
            "model": "D-45",
            "engine": "2.8L I4",
            "body_style": "Sedan"
        },
        {
            "id": 116,
            "year": 1916,
            "manufacturer": "Oldsmobile",
            "model": "Model 44",
            "engine": "3.0L I4",
            "body_style": "Sedan"
        },
        {
            "id": 117,
            "year": 1915,
            "manufacturer": "Hudson",
            "model": "Model 6-40",
            "engine": "3.5L I4",
            "body_style": "Sedan"
        },
        {
            "id": 118,
            "year": 1914,
            "manufacturer": "Ford",
            "model": "Model T",
            "engine": "2.9L I4",
            "body_style": "Sedan"
        },
        {
            "id": 119,
            "year": 1913,
            "manufacturer": "Chalmers",
            "model": "Model 17",
            "engine": "3.3L I4",
            "body_style": "Sedan"
        },
        {
            "id": 120,
            "year": 1912,
            "manufacturer": "Buick",
            "model": "Model 35",
            "engine": "2.7L I4",
            "body_style": "Sedan"
        },
        {
            "id": 121,
            "year": 1911,
            "manufacturer": "Cadillac",
            "model": "Model 30",
            "engine": "4.0L I4",
            "body_style": "Sedan"
        },
        {
            "id": 122,
            "year": 1910,
            "manufacturer": "Hudson",
            "model": "Model 20",
            "engine": "3.3L I4",
            "body_style": "Sedan"
        },
        {
            "id": 123,
            "year": 1909,
            "manufacturer": "Ford",
            "model": "Model N",
            "engine": "2.8L I4",
            "body_style": "Sedan"
        },
        {
            "id": 124,
            "year": 1908,
            "manufacturer": "Oldsmobile",
            "model": "Model F",
            "engine": "3.0L I4",
            "body_style": "Sedan"
        },
        {
            "id": 125,
            "year": 1907,
            "manufacturer": "Buick",
            "model": "Model D",
            "engine": "2.6L I4",
            "body_style": "Sedan"
        },
        {
            "id": 126,
            "year": 1906,
            "manufacturer": "Cadillac",
            "model": "Model K",
            "engine": "4.2L I4",
            "body_style": "Sedan"
        },
        {
            "id": 127,
            "year": 1905,
            "manufacturer": "Reo",
            "model": "Runabout",
            "engine": "2.7L I2",
            "body_style": "Convertible"
        },
        {
            "id": 128,
            "year": 1904,
            "manufacturer": "Ford",
            "model": "Model C",
            "engine": "2.9L I4",
            "body_style": "Sedan"
        },
        {
            "id": 129,
            "year": 1903,
            "manufacturer": "Oldsmobile",
            "model": "Curved Dash",
            "engine": "1.6L I1",
            "body_style": "Convertible"
        },
        {
            "id": 130,
            "year": 1902,
            "manufacturer": "Buick",
            "model": "Model B",
            "engine": "2.6L I4",
            "body_style": "Sedan"
        },
        {
            "id": 131,
            "year": 1901,
            "manufacturer": "Oldsmobile",
            "model": "Model R",
            "engine": "2.2L I4",
            "body_style": "Sedan"
        },
        {
            "id": 132,
            "year": 1900,
            "manufacturer": "Packard",
            "model": "Model A",
            "engine": "3.3L I4",
            "body_style": "Sedan"
        },
        {
            "id": 133,
            "year": 1899,
            "manufacturer": "Duryea",
            "model": "Runabout",
            "engine": "2.5L I2",
            "body_style": "Convertible"
        },
        {
            "id": 134,
            "year": 1898,
            "manufacturer": "Winton",
            "model": "Motor Carriage",
            "engine": "2.7L I2",
            "body_style": "Convertible"
        },
        {
            "id": 135,
            "year": 1897,
            "manufacturer": "Locomobile",
            "model": "Steam Carriage",
            "engine": "2.9L I2",
            "body_style": "Convertible"
        },
        {
            "id": 136,
            "year": 1896,
            "manufacturer": "Haynes",
            "model": "Apperson",
            "engine": "3.0L I2",
            "body_style": "Convertible"
        },
        {
            "id": 137,
            "year": 1895,
            "manufacturer": "Benz",
            "model": "Velo",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 138,
            "year": 1894,
            "manufacturer": "Peugeot",
            "model": "Type 5",
            "engine": "1.3L I1",
            "body_style": "Convertible"
        },
        {
            "id": 139,
            "year": 1893,
            "manufacturer": "Panhard",
            "model": "Et Levassor",
            "engine": "2.4L I2",
            "body_style": "Convertible"
        },
        {
            "id": 140,
            "year": 1892,
            "manufacturer": "Daimler",
            "model": "Motor Carriage",
            "engine": "2.0L I2",
            "body_style": "Convertible"
        },
        {
            "id": 141,
            "year": 1891,
            "manufacturer": "Renault",
            "model": "Type A",
            "engine": "1.5L I1",
            "body_style": "Convertible"
        },
        {
            "id": 142,
            "year": 1890,
            "manufacturer": "Mercedes",
            "model": "Benz",
            "engine": "2.2L I2",
            "body_style": "Convertible"
        },
        {
            "id": 143,
            "year": 1889,
            "manufacturer": "Peugeot",
            "model": "Type 3",
            "engine": "2.0L I2",
            "body_style": "Convertible"
        },
        {
            "id": 144,
            "year": 1888,
            "manufacturer": "Benz",
            "model": "Motorwagen",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 145,
            "year": 1887,
            "manufacturer": "Daimler",
            "model": "Motor Carriage",
            "engine": "1.8L I2",
            "body_style": "Convertible"
        },
        {
            "id": 146,
            "year": 1886,
            "manufacturer": "Benz",
            "model": "Patent-Motorwagen",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 147,
            "year": 1885,
            "manufacturer": "Peugeot",
            "model": "Type 2",
            "engine": "1.3L I1",
            "body_style": "Convertible"
        },
        {
            "id": 148,
            "year": 1884,
            "manufacturer": "Daimler",
            "model": "Riding Car",
            "engine": "1.5L I2",
            "body_style": "Convertible"
        },
        {
            "id": 149,
            "year": 1883,
            "manufacturer": "Benz",
            "model": "Riding Car",
            "engine": "1.5L I2",
            "body_style": "Convertible"
        },
        {
            "id": 150,
            "year": 1882,
            "manufacturer": "Peugeot",
            "model": "Type 1",
            "engine": "1.2L I1",
            "body_style": "Convertible"
        },
        {
            "id": 151,
            "year": 1881,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.5L I2",
            "body_style": "Convertible"
        },
        {
            "id": 152,
            "year": 1880,
            "manufacturer": "Benz",
            "model": "Prototype",
            "engine": "1.3L I1",
            "body_style": "Convertible"
        },
        {
            "id": 153,
            "year": 1879,
            "manufacturer": "Peugeot",
            "model": "Prototype",
            "engine": "1.1L I1",
            "body_style": "Convertible"
        },
        {
            "id": 154,
            "year": 1878,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.0L I2",
            "body_style": "Convertible"
        },
        {
            "id": 155,
            "year": 1877,
            "manufacturer": "Benz",
            "model": "Prototype",
            "engine": "1.1L I1",
            "body_style": "Convertible"
        },
        {
            "id": 156,
            "year": 1876,
            "manufacturer": "Peugeot",
            "model": "Prototype",
            "engine": "1.1L I1",
            "body_style": "Convertible"
        },
        {
            "id": 157,
            "year": 1875,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.0L I2",
            "body_style": "Convertible"
        },
        {
            "id": 158,
            "year": 1874,
            "manufacturer": "Benz",
            "model": "Prototype",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 159,
            "year": 1873,
            "manufacturer": "Peugeot",
            "model": "Prototype",
            "engine": "1.1L I1",
            "body_style": "Convertible"
        },
        {
            "id": 160,
            "year": 1872,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.1L I2",
            "body_style": "Convertible"
        },
        {
            "id": 161,
            "year": 1871,
            "manufacturer": "Benz",
            "model": "Prototype",
            "engine": "1.1L I1",
            "body_style": "Convertible"
        },
        {
            "id": 162,
            "year": 1870,
            "manufacturer": "Peugeot",
            "model": "Prototype",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 163,
            "year": 1869,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.1L I2",
            "body_style": "Convertible"
        },
        {
            "id": 164,
            "year": 1868,
            "manufacturer": "Benz",
            "model": "Prototype",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 165,
            "year": 1867,
            "manufacturer": "Peugeot",
            "model": "Prototype",
            "engine": "1.1L I1",
            "body_style": "Convertible"
        },
        {
            "id": 166,
            "year": 1866,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.1L I2",
            "body_style": "Convertible"
        },
        {
            "id": 167,
            "year": 1865,
            "manufacturer": "Benz",
            "model": "Prototype",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 168,
            "year": 1864,
            "manufacturer": "Peugeot",
            "model": "Prototype",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 169,
            "year": 1863,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.1L I2",
            "body_style": "Convertible"
        },
        {
            "id": 170,
            "year": 1862,
            "manufacturer": "Benz",
            "model": "Prototype",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 171,
            "year": 1861,
            "manufacturer": "Peugeot",
            "model": "Prototype",
            "engine": "1.1L I1",
            "body_style": "Convertible"
        },
        {
            "id": 172,
            "year": 1860,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.1L I2",
            "body_style": "Convertible"
        },
        {
            "id": 173,
            "year": 1859,
            "manufacturer": "Benz",
            "model": "Prototype",
            "engine": "1.1L I1",
            "body_style": "Convertible"
        },
        {
            "id": 174,
            "year": 1858,
            "manufacturer": "Peugeot",
            "model": "Prototype",
            "engine": "1.0L I1",
            "body_style": "Convertible"
        },
        {
            "id": 175,
            "year": 1857,
            "manufacturer": "Daimler",
            "model": "Prototype",
            "engine": "1.1L I2",
            "body_style": "Convertible"
        },
    ];

    const findCarsByYear = (year) => {
        return car.filter((car) => car.year === parseInt(year));
    };
 

    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        setSelectedCar(selectedYear);
        const cars = findCarsByYear(selectedYear);
        setFilteredCars(cars);
    };
    
    const handleManuChange = (event) => {
        setSelecteManufacturer(event.target.value);
    };
    const handleModelChange = (event) => {
        setSelectedModel(event.target.value);
    };
    const handleEngineChange = (event) => {
        setSelecteEngine(event.target.value);
    };
    
    const handleCarChange = (event) => {
        setSelectedCar(event.target.value);
        const cars = findCarsByYear(selectedYear);
    };

   localStorage.setItem("Engine", selecteEngine);
   localStorage.setItem("Body", selecteBody);
   localStorage.setItem("Year", selectedCar);
   
   localStorage.setItem("Model", selectedModel);
   
    const handleBodyChange = (event) => {
        setSelecteBody(event.target.value);
    };
    const handleFormSubmit = async (event) => {
        event.preventDefault();
    
        const userId = localStorage.getItem("id");
        if (!userId) {
          alert("Please log in to submit your car details.");
          return;
        }
    
        const carDetails = {
          userId,
          year: selectedCar,
          manufacturer: selecteManufacturer,
          model: selectedModel,
          body_styles: selecteBody,
          engine: selecteEngine,
        };
    
        localStorage.setItem("Engine", selecteEngine);
        localStorage.setItem("Body", selecteBody);
        localStorage.setItem("Year", selectedCar);
        localStorage.setItem("Model", selectedModel);
    
        try {
          const response = await axios.post('https://backend.fredautorepair.com/car-details', carDetails, {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': apiKey,
            },
          });
          console.log("Car details submitted successfully:", response.data);
          // Handle success actions (e.g., show success message or redirect)
        } catch (error) {
          console.error("Error submitting car details:", error);
          // Handle error actions (e.g., show error message)
        }
      };
  
    return (
        <form className="dropdown-container1" onSubmit={handleFormSubmit}>
        <div className="dropdown-container">
              <div className="logo1">
                <img src="https://pictures.fredautorepair.com/pictures/Variante_1.png" alt="Fred auto repair" />
            </div>
            <div className="dropdown_car">
            <img src="https://pictures.fredautorepair.com/pictures/fredautorepair.png" alt="Car Image" className="car-image" />
            </div>
            <div className="dropdown_message1">
                <h2> It all starts here,<h4>Add your car to see exact fit parts</h4> </h2>
               
            </div>
            <div className="dropdown">
                <select className="select-arrow" value={selectedCar} onChange={handleYearChange}>
                    <option value="">Select a year</option>
                    {car.map((year,index) => (
                        <option key={index} value={year.year}>
                            {year.year}
                        </option>
                    ))}
                </select>
            </div>
            <div className="dropdown">
                <select value={selecteManufacturer} onChange={handleManuChange}>
                    <option value="">Select a Manufacturer</option>
                    {car.map((model,index) => (
                        <option key={index} value={model.manufacturer}>
                            {model.manufacturer}
                        </option>
                    ))}
                </select>
            </div>
            <div className="dropdown">
                <select value={selectedModel} onChange={handleModelChange}>
                    <option value="">Select a model</option>
                    {car.map((model,index) => (
                        <option key={index} value={model.model}>
                            {model.model}
                        </option>
                    ))}
                </select>
            </div>
            <div className="dropdown">
                <select value={selecteBody} onChange={handleBodyChange}>
                    <option value="">Select a body style</option>
                    {car.map((model,index) => (
                        <option key={index} value={model.body_style}>
                            {model.body_style}
                        </option>
                    ))}
                </select>
            </div>
            <div className="dropdown">
                <select value={selecteEngine} onChange={handleEngineChange}>
                    <option value="">Select an Engine</option>
                    {car.map((model,index) => (
                        <option key={index} value={model.engine}>
                            {model.engine}
                        </option>
                    ))}
                </select>
            </div>
         
        </div>
        <button type="submit" className="submit-button">Addcar</button>
        </form>
    );
};

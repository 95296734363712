// src/components/Login.js

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';
import './login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Dispatch loginUser action with email and password
      // Assuming loginUser action correctly dispatches an async action and returns a promise
      const actionResult = await dispatch(loginUser({ email, password }));
      // Assuming the actionResult follows the Flux Standard Action (FSA) convention where
      // action objects have a type and a payload property, and possibly error and meta properties.
      // const { accessToken, refreshToken, id } = actionResult.data;
  console.log(actionResult)
      // Store access token, refresh token, and user ID in local storage
      // localStorage.setItem('accessToken', accessToken);
      // localStorage.setItem('refreshToken', refreshToken);
      // localStorage.setItem('id', id);
  
      // Redirect to homepage on successful login
      history.push('/');
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Error logging in. Please try again later.');
    }
  };
  console.log(email, password);

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input required type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input required type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit" className="login-button">Login</button>
        {error && <p className="error">{error}</p>}
      </form>
      <button onClick={() => history.push('/register')} className="register-button">Register</button>
    </div>
  );
};

export default Login;
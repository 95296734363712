import React, { useState } from "react";
import useForm from "../../utils/useForm";
import './contact.css';

const apiKey = 'yehh^Yhhhdhd%^^yhhhddhgh38839477^%%%44ggghh';

const Contact = () => {
  const submitForm = async () => {
    try {
      const response = await fetch('https://backend.fredautorepair.com/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      alert('Contact info submitted successfully!');
      console.log('Contact submitted successfully:', data);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const { values, handleChange, handleSubmit } = useForm(submitForm);

  return (
    <div>
      <section className="page-title  v3 clearfix parallax  parallax5">
        <div className="overlay"></div>
        <div className="container">
          <div className="wrap-page-title">
            <div className="breakcrums text-center v2">
              <ul>
                <li>
                  <a href="#" className="hover-text">HOME</a>
                </li>
                <li>
                  <a href="#" className="hover-text">PAGES</a>
                </li>
                <li>
                  <a href="#" className="hover-text">Contact</a>
                </li>
              </ul>
            </div>
            <div className="page-title-heading text-center v2">
              <h1>
                <a href="#" className="hover-text">Contact Us</a>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <div className="">
        <img src="https://pictures.fredautorepair.com/pictures/officemap.png" alt="Map" className="map-image" />
      </div>

      <div className="content-wrap contact-v1-page">
        <div className="flat-spacer clearfix" data-desktop="100" data-mobile="60" data-smobile="60"></div>
        <div className="contact-container clearfix">
          <div className="flat-contact-us contact-v1-page">
            <div className="flat-title">
              <h2>CONTACT US</h2>
            </div>
            <div className="contact-flat-iconbox style2 v2 clearfix">
              <div className="iconbox v2 style2">
                <div className="iconbox-icon">
                  <span className="icon_pin_alt"></span>
                </div>
                <div className="iconbox-content">
                  <a href="#" className="hover-text">
                    <p>7322 ave Rene Descartes, Montreal</p>
                  </a>
                </div>
              </div>

              <div className="iconbox v2 style2">
                <div className="iconbox-icon">
                  <span className="icon_mobile"></span>
                </div>
                <div className="iconbox-content">
                  <a href="#" className="hover-text">
                    <p>514-991-1279</p>
                  </a>
                  <a href="#" className="hover-text">
                    <p>+514-991-1279</p>
                  </a>
                </div>
              </div>

              <div className="iconbox v2 style2">
                <div className="iconbox-icon">
                  <span className="icon_mail_alt"></span>
                </div>
                <div className="iconbox-content">
                  <a href="#" className="hover-text">
                    <p>info@fredautorepair.com</p>
                  </a>
                  <a href="#" className="hover-text">
                    <p>info@fredautorepair.com</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flat-form-contact contact-v1-page">
            <div className="flat-title">
              <h2>GET IN TOUCH</h2>
           
            </div>
            <div className="flat-desc">
              <h2>Need more information? Get in touch with the professionals at FredAutoRepair Group for quality services at competitive prices.</h2></div>
            
            <div className="flat-spacer clearfix" data-desktop="40" data-mobile="40" data-smobile="40"></div>
            <form onSubmit={handleSubmit}>
              <div className="wrap-input">
                <input
                  type="text"
                  name="name"
                  placeholder="name"
                  required="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              
              <div className="wrap-input">
                <input
                  type="email"
                  name="email"
                  required=""
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
              <div className="wrap-input">
                <input
                  type="text"
                  name="subject"
                  required=""
                  placeholder="Subject"
                  value={values.subject}
                  onChange={handleChange}
                />
              </div>
              <div className="wrap-textarea">
                <textarea
                  name="message"
                  required=""
                  placeholder="Message"
                  value={values.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="wrap-btn">
                <button className="btn-effect btn-message" type="submit">
                  Send messages
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flat-spacer clearfix" data-desktop="100" data-mobile="60" data-smobile="60"></div>
      </div>
      <section className="flat-contact v1 clearfix">
        <div className="container">
          <div className="wrap-text">
            <h3 className="title">
              <a href="#">Have you question or need any help for work consulant?</a>
            </h3>
          </div>
          <div className="wrap-btn text-right">
            <a href="#" className="flat-button btn-contact bg-contact">
              Contact us
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;

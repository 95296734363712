// src/redux/reducer.js

import {
    FETCH_MESSAGES_REQUEST,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_FAILURE,
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAILURE,
  } from '../actions/actionTypes';
  
  const initialState = {
    loading: false,
    messages: [],
    error: null,
  };
  
  const chatReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MESSAGES_REQUEST:
      case SEND_MESSAGE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_MESSAGES_SUCCESS:
        return {
          ...state,
          loading: false,
          messages: action.payload,
        };
      case SEND_MESSAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          messages: [...state.messages, action.payload],
        };
      case FETCH_MESSAGES_FAILURE:
      case SEND_MESSAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default chatReducer;
  

import { SAVEAPPOINTMENT } from './../actions/actionTypes';
import { axios } from 'axios';
import Axios from "axios";
const initialState = async () => {
  const result = await Axios.get("http://localhost:3001/appointments");

  return result.data;
};


const AppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVEAPPOINTMENT:
            return "hello";

        default:
            return state;

    }
}

export default AppointmentReducer;
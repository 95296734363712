// src/reducers/cart.js
const initialState = {
    cartItems: [],
  };
  
  export default function cartReducer(state = initialState, action) {
    switch (action.type) {
      case 'ADD_TO_CART':
        return {
          ...state,
          cartItems: [...state.cartItems, action.payload],
        };
      case 'REMOVE_FROM_CART':
        return {
          ...state,
          cartItems: state.cartItems.filter(item => item.id !== action.payload.id),
        };
      default:
        return state;
    }
  }
  
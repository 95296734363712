import React from "react";
import User from "./components/User";

import App from "./App";
import Services from "./pages/services/Services";
import Gallery from "./pages/gallery/Gallery";
import Appointment from "./pages/appointment/Appointment";
import Shop from "./pages/shop/Shop";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import OurTeam from "./pages/ourTeam/OurTeam";
import Faqs from "./components/faqs/Faqs";
import Checkout from "./pages/shop/Checkout";
import NavBar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import login from "./components/login/login.js";
import { Register } from "./components/register/registerForm.js";
import ProtectedRoute from "./ProtectedRoute";  // Import the ProtectedRoute component
import ProductDetails from "./pages/productPage/ProductDetails.js";
import DetailPage from './pages/DetailedPage/DetailedPage.js';

// import DetailProduct from './pages/productPage/';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";







const Routes = () => {
  return (
    <BrowserRouter>
      <Route
        path="/"
        render={({ location }) =>
          location.pathname !== "/login" &&   location.pathname.toLowerCase() !== "/ProductDetails" &&
          location.pathname.toLowerCase() !== "/register" && <NavBar />
        }
      />
      <Switch>
        {/* <Route exact path="/" component={App} /> */}
        {/* <Route exact path="/services" component={Services} /> */}
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/DetailPage/:id" component={DetailPage} />
        {/* <Route exact path="/productPage" component={ProductPage} /> */}
        {/* <Route exact path="/product/:id" component={ProductDetails} /> */}
        {/* <Route exact path="/appointment" component={Appointment} /> */}
        <Route exact path="/" component={App} />
        <Route exact path="/shop" component={Shop} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route exact path="/ourteam" component={OurTeam} />
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/login" component={login} />
        <Route exact path="/register" component={Register} />
        {/* <Route exact path="/ProductDetails" component={ProductDetails} /> */}
        <ProtectedRoute path="/checkout" component={Checkout} /> {/* Use ProtectedRoute for the Checkout page */}
        <Redirect from="/login" to="/" /> {/* Redirect from login to shop page */}
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};
export default Routes;
// defiend all routers here applicatoin start from this file

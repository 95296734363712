import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './checkout.css';
import html2pdf from 'html2pdf.js';
import config from '../../config.json';

const Checkout = () => {
  const [cart, setCart] = useState(null);
  const [error, setError] = useState(null);
 
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [conversionResults, setConversionResults] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState('CAN');
  const [discountCode, setDiscountCode] = useState('');
  const [discountedTotal, setDiscountedTotal] = useState(null);
  // const [apiKey, setApiKey] = useState('');
 


const  apiKey  = config.apiKeys;






  // const fetchApiKey = async () => {
  //   try {
  //     const response = await axios.post(`https://backend.fredautorepair.com/apikey/new?apiKey=initial-api-key`, null, {
  //       headers: {
  //         'x-api-key': apiKeys,
  //       },
  //     });
  //     console.log(response.data);
  //     setApiKey(response.data.apiKey);
  //   } catch (error) {
  //     console.error('Error generating API key:', error);
  //     setError('There was an error generating the API key.');
  //   }
  // };

  // const validateApiKey = async (Apikey) => {
  //   try {
  //     const response = await axios.get(`${config.apiBaseUrl}/apikey/validate`, {
  //       headers: {
  //         'x-api-key': apiKey,
  //       },
  //     });
  //     return response.data.isValid;
  //   } catch (error) {
  //     console.error('Error validating API key:', error);
  //     return false;
  //   }
  // };



  const fetchCart = async (userId) => {
    try {
      
     
      const response = await fetch(`https://backend.fredautorepair.com/cart/user/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
      });
      console.log(response.data);
      if (!response.ok) {
        throw new Error('Failed to fetch cart');
      }
      const data = await response.json();
      setCart(data);
      await fetchConversions(data.totalPrice);
      localStorage.setItem('accessToken', data.accessToken);
    } catch (error) {
      console.error('Error fetching cart:', error);
      setError('Failed to fetch cart');
    }
  };


  // useEffect(() => {
  //   fetchApiKey();
  // }, []);








  const convertCurrency = async (amount, from, to) => {
    try {
      const response = await axios.get(`https://backend.fredautorepair.com/mock-convert?amount=${amount}&from=${from}&to=${to}`, {
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": apiKey,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error during currency conversion:', error);
      return null;
    }
  };

  const fetchConversions = async (totalPrice) => {
    const conversions = {};
    conversions.USD = await convertCurrency(totalPrice, 'CAN', 'USD');
    conversions.GBP = await convertCurrency(totalPrice, 'CAN', 'GBP');
    conversions.EUR = await convertCurrency(totalPrice, 'CAN', 'EUR');
    conversions.JPY = await convertCurrency(totalPrice, 'CAN', 'YEN');
    setConversionResults(conversions);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      setIsAuthenticated(true);
      const userId = localStorage.getItem('id');
      if (userId) {
        fetchCart(userId);
      } else {
        setError('User ID not found in local storage');
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // const fetchCart = async (userId) => {
  //   try {
  //     const response = await fetch(`https://backend.fredautorepair.com/cart/user/${userId}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-api-key': apiKey,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch cart');
  //     }

  //     const data = await response.json();
  //     setCart(data); // Update cart state with fetched data
  //     await fetchConversions(data.totalPrice);
  //     localStorage.setItem('accessToken', data.accessToken);
  //     localStorage.setItem('refreshToken', data.refreshToken);
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setError('There was an error fetching the cart data.');
  //   }
  // };

  const validateDiscountCode = async () => {
    try {
      const response = await axios.post('https://backend.fredautorepair.com/Discounts/apply', {
        code: discountCode,totalAmount: cart.totalPrice
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        }
      });

      if (response.data.newTotal) {
        const discountAmount = (cart.totalPrice * response.data.discountPercentage) / 100;
        setDiscountedTotal(response.data.newTotal);
      } else {
        alert('Invalid or expired discount code');
        setDiscountedTotal(null);
      }
    } catch (error) {
      console.error('Error validating discount code:', error);
      alert('There was an error validating the discount code.');
    }
  };

  const handleCurrencyChange = async (event) => {
    const currency = event.target.value;
    setSelectedCurrency(currency);
    const conversion = await convertCurrency(cart.totalPrice, 'CAN', currency);
    setConversionResults((prevResults) => ({
      ...prevResults,
      [currency]: conversion
    }));
  };

  const downloadInvoice = () => {
    const input = document.getElementById('invoice');
    const userEmail = localStorage.getItem('email');
    const fileName = `Fred_auto_cart_invoice_${userEmail}.pdf`;
    const opt = {
      margin: 0,
      filename: fileName,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    html2pdf().from(input).set(opt).save();
  };

  return (
    <div>
      <section className="page-title v2 clearfix parallax parallax6">
        <div className="overlay"></div>
        <div className="container">
          <div className="wrap-page-title">
            <div className="page-title-heading text-center v1">
              <h1>
                <a href="#" className="hover-text">
                  CHECKOUT PAGE
                </a>
              </h1>
            </div>

            <div className="breakcrums text-center v2">
              <ul>
                <li>
                  <a href="#" className="hover-text">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#" className="hover-text">
                    Shop
                  </a>
                </li>
                <li>
                  <a href="#" className="hover-text">
                    Checkout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="content-wrap contact-v1-page">
        <div
          className="flat-spacer clearfix"
          data-desktop="100"
          data-mobile="60"
          data-smobile="60"
        ></div>
        <div className="checkout-container clearfix">
          <div className="flat-contact-us contact-v3-page">
            <div className="checkout-flat-title">
              <h2>Invoice</h2>
            </div>
          </div>

          <div className="checkout-container">
            <div className="checkout-user-cart-info">
              <h3>User Information</h3>
              <div className="user-info">
                <p>Customer: {localStorage.getItem('email')}</p>
              </div>
              {cart && (
                <div className="checkout-cart-details" id="invoice">
                  <h3>Invoice Details</h3>
                  <p>Customer ID: {localStorage.getItem('id')}</p>
                  <p>Customer Email: {localStorage.getItem('email')}</p>
                  <p>Invoice Number: {cart.id}</p>
                  <ul>
                    {cart.items.map((item) => (
                      <li key={item.id}>
                        <img
                          src={item.product.img}
                          alt="Image"
                          width="60"
                          height="60"
                        />
                        <h3>{item.product.name}</h3>
                        <p>
                          Price: ${item.product.price} x {item.quantity} = ${item.amount}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <p>Total: ${cart.totalPrice}</p>
                  {discountedTotal !== null && (
                    <p>Discounted Total: ${discountedTotal}</p>
                  )}

                  <div className="discount-code-section">
                    <label htmlFor="discountCode">Discount Code</label>
                    <input
                      type="text"
                      id="code"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                      placeholder="Enter discount code"
                    />
                    <button onClick={validateDiscountCode}>Apply Discount</button>
                  </div>

                  <div>
                    <label htmlFor="currency">Convert Total to: </label>
                    <select id="currency" value={selectedCurrency} onChange={handleCurrencyChange}>
                      <option value="CAN">CAD</option>
                      <option value="USD">USD</option>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="YEN">YEN</option>
                    </select>
                    {selectedCurrency !== 'CAN' && (
                      <p>Total in {selectedCurrency}: {conversionResults[selectedCurrency]}</p>
                    )}
                  </div>
                </div>
              )}
              {error && <p className="error">{error}</p>}
              <button onClick={downloadInvoice} className="download-button">Download Invoice</button>
            </div>

            <div className="checkout-flat-form-contact contact-v2-page">
              <form>
                <label htmlFor="name">Name</label>
                <div className="wrap-input">
                  <input
                    type="text"
                    id="name"
                    value={''}
                    placeholder="name"
                    name="name"
                    required
                  />
                </div>

                <label htmlFor="address">Address</label>
                <input type="text" id="address" name="address" required />

                <div className="form-group">
                  <label htmlFor="card">Credit Card</label>
                  <input type="text" id="card" name="card" required />
                </div>
                <div className="form-group">
                  <label htmlFor="expiry">Expiry Date</label>
                  <input type="date" id="expiry" name="expiry" placeholder="MM/YY" required />
                </div>
                <div className="form-group">
                  <label htmlFor="cvv">Secret Code (CVV)</label>
                  <input type="text" id="cvv" name="cvv" required />
                </div>
                <div className="wrap-btn text-right">
                  <a href="/contact.html" className="flat-button btn-contact bg-contact">
                    Submit now
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

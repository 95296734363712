// src/redux/actions.js
import config from '../../config.json';
import axios from 'axios';
import {
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
} from './actionTypes';

const apiUrl = 'https://backend.fredautorepair.com';
// Fetch Messages
const apiKey = config.apiKeys;
export const fetchMessages = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MESSAGES_REQUEST });
        try {
            const response = await axios.get(`${apiUrl}/chat/messages`, {
                headers: {
                    "x-api-key": apiKey
                }
            });
            console.log(response.data);
            dispatch({ type: FETCH_MESSAGES_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: FETCH_MESSAGES_FAILURE, error: error.message });
        }
    };
}; 

// Send Message
// Send Message
export const sendMessage = (message) => {
    return async (dispatch) => {
        dispatch({ type: SEND_MESSAGE_REQUEST });
        try {
            const response = await axios.post(`${apiUrl}/chat/messages`, { message }, {
                headers: {
                    "x-api-key": apiKey
                }
            });
            dispatch({ type: SEND_MESSAGE_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: SEND_MESSAGE_FAILURE, error: error.message });
        }
    };
};

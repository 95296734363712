import axios from 'axios';
import config from '../../config.json';
import {
 FETCH_PRODUCTS_REQUEST ,
 FETCH_PRODUCTS_SUCCESS ,
 FETCH_PRODUCTS_FAILURE ,
 FETCH_USER_SUCCESS,
 FETCH_USER_FAILURE,
 FETCH_ARTICLES_REQUEST,
 FETCH_ARTICLES_SUCCESS ,
 FETCH_ARTICLES_FAILURE,
 DELETE_CART_ITEM_SUCCESS,
 DELETE_CART_ITEM_FAILURE,
 FETCH_CARS_REQUEST ,
 FETCH_CARS_SUCCESS,
 FETCH_CARS_FAILURE,
 FETCH_CART_REQUEST,
 FETCH_YEARS_REQUEST,
 FETCH_YEARS_SUCCESS ,
 FETCH_YEARS_FAILURE ,
 FETCH_CART_SUCCESS,
 ADD_TO_CART_SUCCESS,
 FETCH_CART_FAILURE,
 ADD_TO_CART_FAILURE,
 SET_SEARCH_RESULTS,
 REMOVE_CART_ITEM_SUCCESS,
 ADD_TO_CART,
 UPDATE_TOTAL_PRICE,
 REMOVE_CART_ITEM,
 FETCH_CAR_DETAILS_FAILURE,
  FETCH_CAR_DETAILS_REQUEST,
  FETCH_CAR_DETAILS_SUCCESS,
  TOGGLE_CAR_DETAIL,
  ADD_CAR_DETAIL,
} from './actionTypes';
const apiKey = config.apiKeys;


export const setSearchResults = (searchResults) => ({
  type: SET_SEARCH_RESULTS,
  payload: searchResults,
});

export const addToCart0 = (productId, quantity) => (dispatch, getState) => {
  const userId = localStorage.getItem('id');
  // Assuming you have an API call to add the item to the cart
  axios.post(`https://backend.fredautorepair.com/cart/${userId}/items`, { productId, quantity }, {
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": apiKey,
    },
  }).then((response) => {
    dispatch({ type: ADD_TO_CART, payload: response.data });
  }).catch(error => {
    console.error('Error adding item to cart:', error);
  });

  const { cartItems } = getState().shop;
  const totalPrice = cartItems.reduce((sum, item) => sum + item.amount * item.quantity, 0);
  dispatch({ type: UPDATE_TOTAL_PRICE, payload: totalPrice });
};
// export const setSearchResults = (searchQuery) => (dispatch, getState) => {
//   if (typeof searchQuery !== 'string') {
//       searchQuery = '';
//   }

//   const { products } = getState().shop;
//   const filteredProducts = products.filter(product =>
//       product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       product.keywords.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   dispatch({ type: 'SET_SEARCH_RESULTS', payload: filteredProducts });
// };

export const removeCartItem = (cartItemId) => (dispatch, getState) => {
  const cartId = localStorage.getItem('cartId');
  // Assuming you have an API call to remove the item from the cart
  axios.delete(`https://backend.fredautorepair.com/cart/${cartId}/item/${cartItemId}`, {
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": apiKey,
    },
  }).then(() => {
    dispatch({ type: REMOVE_CART_ITEM, payload: cartItemId });
    const { cartItems } = getState().shop;
    const totalPrice = cartItems.reduce((sum, item) => sum + item.amount * item.quantity, 0);
    dispatch({ type: UPDATE_TOTAL_PRICE, payload: totalPrice });
  }).catch(error => {
    console.error('Error removing cart item:', error);
  });
};
export const removeCartItem0 = (cartItemId) => async (dispatch, getState) => {
  const { cartNumber, cartItems } = getState().shop;
  const cartId = localStorage.getItem('cartId');
console.log(cartNumber,cartItems);
  try {
    const updatedCartItems = cartItems.filter(item => item.id !== cartItemId);
    const updatedTotalPrice = updatedCartItems.reduce((sum, item) => sum + item.product.amount * item.quantity, 0);

    dispatch({
      type: REMOVE_CART_ITEM_SUCCESS,
      payload: {
        cartItems: updatedCartItems,
        totalPrice: updatedTotalPrice,
        totalItems: updatedCartItems.length,
      },
    });

    await axios.delete(`https://backend.fredautorepair.com/cart/${cartId}/item/${cartItemId}`, {
      headers: {
        'Content-Type': 'application/json',
        "x-api-key": apiKey,
      },
    });
  } catch (error) {
    dispatch({ type: DELETE_CART_ITEM_FAILURE, error: error.message });
    console.error("Error removing cart item:", error);
  }
};

export const removeCartItempold = (cartItemId) => async (dispatch, getState) => {
    const { cartNumber, cartItems } = getState().shop;
  
    try {
      const updatedCartItems = cartItems.filter(item => item.id !== cartItemId);
      const updatedTotalPrice = updatedCartItems.reduce((sum, item) => sum + item.product.amount * item.quantity, 0);
  
      dispatch({
        type: REMOVE_CART_ITEM_SUCCESS,
        payload: {
          cartItems: updatedCartItems,
          totalPrice: updatedTotalPrice,
          totalItems: updatedCartItems.length,
          
        },
      });
  
      await axios.delete(`https://backend.fredautorepair.com/cart/${cartNumber}/item/${cartItemId}`, {
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": apiKey,
        },
      });
    } catch (error) {
      console.error("Error removing cart item:", error);
    }
  };

  // export const updateTotalItems = (totalItems) => ({
  //   type: 'shop/updateTotalItems',
  //   payload: totalItems,
  // });

// 
export const fetchCarDetails = () => async (dispatch) => {
  const userId = localStorage.getItem('id');

  if (!userId) {
      dispatch({ type: FETCH_CAR_DETAILS_FAILURE, payload: 'User ID not found in local storage' });
      return;
  }

  dispatch({ type: FETCH_CAR_DETAILS_REQUEST });

  try {
      const response = await axios.get(`https://backend.fredautorepair.com/car-details/user/${userId}`, {
          headers: {
              'Content-Type': 'application/json',
              'x-api-key': apiKey,
          },
      });
      console.log(response.data);
      dispatch({ type: FETCH_CAR_DETAILS_SUCCESS, payload: response.data });
  } catch (error) {
      dispatch({ type: FETCH_CAR_DETAILS_FAILURE, payload: 'Error fetching car details' });
  }
};
export const toggleCarDetail = (updatedCarDetails) => ({
  type: TOGGLE_CAR_DETAIL,
  payload: updatedCarDetails,
});



export const addCarDetail = (newCarDetail) => async (dispatch) => {
  const userId = localStorage.getItem('id');

  if (!userId) {
      dispatch({ type: FETCH_CAR_DETAILS_FAILURE, payload: 'User ID not found in local storage' });
      return;
  }

  try {
      const response = await axios.post(`https://backend.fredautorepair.com/car-details/user/${userId}`, newCarDetail, {
          headers: {
              'Content-Type': 'application/json',
              'x-api-key': apiKey,
          },
      });
      dispatch({ type: ADD_CAR_DETAIL, payload: response.data });
  } catch (error) {
      dispatch({ type: FETCH_CAR_DETAILS_FAILURE, payload: 'Error adding car detail' });
  }
};
// Assuming this action fetches cart by user ID and updates the Redux state
export const fetchCartByNumber = (userId) => async (dispatch) => {
    try {
      const response = await axios.get(`https://backend.fredautorepair.com/cart/user/${userId}`, {
        headers: {
          "x-api-key": apiKey,
        },
      });
      const cart = await response.data;
      const cartId = cart.id;
      const cartItems = cart.items || [];
      const totalItems = cart.items.lenght;
      const totalPrice = cart.totalPrice;
      console.log(cart.items);
      // dispatch({ type: FETCH_CART_REQUEST, 
      //   // payload: {
      //   //   totalPrice: totalPrice,
      //   //   totalItems: totalItems,
      //   //   cartItems: cartItems,
         
      //   // }
          dispatch({ type: FETCH_CART_SUCCESS, payload: {cartItems,totalItems,totalPrice } });
    //  });
    localStorage.setItem('cartItems', JSON.stringify(response.data.items));
    localStorage.setItem('cartId', JSON.stringify(cartId));

    } catch (error) {
      dispatch({ type: FETCH_CART_FAILURE, payload: error });
    }
  };


export const removeCartItem12 = (cartNumber,CartItemId) => async (dispatch) => {
    try {
        await axios.delete(`https://backend.fredautorepair.com/cart/${cartNumber}/items/${CartItemId}`, {
            headers: {
                "x-api-key": apiKey,
            },
        });
        dispatch({ type: DELETE_CART_ITEM_SUCCESS, payload: CartItemId });
    } catch (error) {
        dispatch({ type: DELETE_CART_ITEM_FAILURE, error: error.message });
    }
};
export const fetchProducts = (page, order) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCTS_REQUEST });
  try {
    const response = await axios.get(`https://backend.fredautorepair.com/cart/products`, {
      headers: {
        "x-api-key": apiKey,
      },
    });
    dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCTS_FAILURE, error: error.message });
  }
};
export const removeCartItem1 = (cartNumber, cartItemId) => async (dispatch) => {
    try {
        const response = await axios.delete(`https://backend.fredautorepair.com/cart/${cartNumber}/item/${cartItemId}`, {
            headers: {
                'Content-Type': 'application/json',
                "x-api-key": apiKey,
            },
        });
        console.log(response.data);
        dispatch({ type: DELETE_CART_ITEM_SUCCESS, payload: cartItemId });

        // Optionally, update the local state
        // Replace the following lines with your own logic
        // const updatedCartItems = cartItems.filter(item => item.id !== cartItemId);
        // setCartItems(updatedCartItems);
        // const updatedTotalPrice = updatedCartItems.reduce((sum, item) => sum + item.product.amount * item.quantity, 0);
        // settotalPrice(updatedTotalPrice);
        // settotalPrice1(updatedCartItems.length);
        console.log(response.data);
    } catch (error) {
        dispatch({ type: DELETE_CART_ITEM_FAILURE, error: error.message });
        console.error("Error removing cart item:", error);
    }
};

export const fetchArticles = () => async (dispatch) => {
  dispatch({ type: FETCH_ARTICLES_REQUEST });
  try {
    const response = await axios.get('https://backend.fredautorepair.com/articles', {
      headers: {
        "x-api-key": apiKey,
      },
    });
    dispatch({ type: FETCH_ARTICLES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_ARTICLES_FAILURE, error: error.message });
  }
};


export const fetchCars = (year) => async (dispatch) => {
  dispatch({ type: FETCH_CARS_REQUEST });
  try {
    const response = await axios.get(`https://backend.fredautorepair.com/cars/search?year=${year}`, {
      headers: {
        "x-api-key": apiKey,
      },
    });
    dispatch({ type: FETCH_CARS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CARS_FAILURE, error: error.message });
  }
};

export const fetchYears = () => async (dispatch) => {
    dispatch({ type: FETCH_YEARS_REQUEST });
    try {
        const response = await axios.get(`https://backend.fredautorepair.com/cars/allyears`, {
            headers: {
                "x-api-key": apiKey,
            },
        });
        dispatch({ type: FETCH_YEARS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_YEARS_FAILURE, error: error.message });
    }
};

export const fetchUser = () => async dispatch => {
    const userId = localStorage.getItem("id");
    if (!userId) {
      console.error("User ID not found in localStorage");
      return;
    }
  
    try {
      const response = await axios.get(`https://backend.fredautorepair.com/users/${userId}`, {
        headers: {
          "x-api-key": apiKey,
        },
      });
  
      const user = response.data;
      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: user,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  

export const addToCart = (productId, quantity) => async (dispatch) => {
  const userId = localStorage.getItem("id");
  console.log(productId, quantity);

  if (!userId) {
    alert("Please log in to add items to your cart.");
    return;
  }

  try {
    const response = await axios.post(
      `https://backend.fredautorepair.com/cart/${userId}/items`,
      {
        productId,
        quantity,
      },
      {
        headers: {
          "x-api-key": apiKey,
        },
      }
    );
    // const cartItems = response.data;
   
console.log(response.data); 
    console.log(`${ADD_TO_CART_SUCCESS} successfully added and updated`,response.data);
    dispatch({ type: UPDATE_TOTAL_PRICE, payload: response.data.totalPrice });
    dispatch({ type: ADD_TO_CART_SUCCESS, payload: response.data });
    localStorage.setItem('cartItems', JSON.stringify(response.data.items));
    localStorage.setItem('cartId', JSON.stringify(response.data.id));
  } catch (error) {
    dispatch({ type: ADD_TO_CART_FAILURE, error: error.message });
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, addToCart } from '../../redux/actions/ShopActions';
import SortBy from '../SortBy/Sortby';
import "./popularProduct.css"; // Make sure to import your CSS file

const PopularProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.shop.products);
  const searchResults = useSelector(state => state.shop.searchResults);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // Number of products per page

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);
console.log(searchResults,sortedProducts);
  useEffect(() => {
    setSortedProducts(searchResults.length > 0 ? searchResults : products);
  }, [products, searchResults]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);
  const displayedProducts = sortedProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <section className="flat-popular-product">
      <div className="container-v2">
        <div className="flat-spacer clearfix" data-desktop="101" data-mobile="60" data-smobile="60"></div>
        <div className="flat-spacer clearfix" data-desktop="66" data-mobile="40" data-smobile="40"></div>
        <div>
          <SortBy className='shop-grid-v8' products={products} setSortedProducts={setSortedProducts} />
        </div>
        <ul className="products style2 clearfix">
          {displayedProducts.map((product, index) => (
            <li className="product-item one-of-four clearfix" key={index}>
              <div className="product-title"> {product.name} </div>
              <div className="product-info"> {product.price} </div>
             
              <div className="product-img">
                <img src={product.img} alt={product.name} />
                <div className="overlay"></div>
                <span className="discount font-Rajdhani">- 15%</span>
                <ul className="list-icon">
                  <li>
                    <a href={`/DetailPage/${product.id}`}>
                      <span className="icon_search"></span>
                    </a>
                  </li>
                  <li>
                    <button
                      onClick={() => dispatch(addToCart(product.id, 1))}
                      className="icon_cart_alt"
                    ></button>
                  </li>
                  <li>
                    <button
                      onClick={() => dispatch(addToCart(product.id, 1))}
                      className="icon_tags_alt"
                    ></button>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
        <div className="flat-paginations v3">
          <ul className="paginations">
            <li>
              <button 
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <span className="arrow_carrot-left"></span>
              </button>
            </li>
            {[...Array(totalPages).keys()].map((pageNumber) => (
              <li key={pageNumber + 1}>
                <button 
                  className={currentPage === pageNumber + 1 ? 'active' : ''}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              </li>
            ))}
            <li>
              <button 
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <span className="arrow_carrot-right"></span>
              </button>
            </li>
          </ul>
        </div>
        <div className="flat-spacer clearfix" data-desktop="0" data-mobile="0" data-smobile="0"></div>
      </div>
    </section>
  );
};

export default PopularProducts;

import axios from 'axios';
import config from '../../config.json';


import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from './actionTypes';

// src/actions/authActions.js
const apiKey = config.apiKeys;
export const loginUser = (userData) => async (dispatch) => {
  try {
    // Make API call to login endpoint
    const response = await axios.post('https://backend.fredautorepair.com/auth/login', userData, {
      headers: {
        "x-api-key": apiKey,
      },
    });
    
    // Extract the required data from the response
    const { accessToken, id, refreshToken } = response.data;
    console.log(response.data.accessToken,response.data.id, response.data.accessToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('id', id);
    localStorage.setItem('refreshToken', refreshToken);

    // Dispatch the success action with the extracted data
    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: { accessToken, id, refreshToken },
    });
  } catch (error) {
    // Dispatch the error action if the API call fails
    dispatch({
      type: LOGIN_USER_FAILURE,
      payload: error.message,
    });
  }
};

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const registerUser = (userData) => async (dispatch) => {
  try {
    // Make API call to register endpoint
    const response = await axios.post('https://backend.fredautorepair.com/user/register', userData, {
      headers: {
      "x-api-key": apiKey
      }
    });
    // Dispatch the success action with the response data
    dispatch({
      type: REGISTER_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // Dispatch the error action if the API call fails
    dispatch({
      type: REGISTER_USER_FAILURE,
      payload: error.message,
    });
  }
};

export const updateUser = (userData) => async (dispatch) => {
  try {
    // Make API call to update user endpoint
    const response = await axios.put('/api/user', userData, {
      headers: {
      "x-api-key": apiKey,
      },
    });
    // Dispatch the success action with the response data
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // Dispatch the error action if the API call fails
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error.message,
    });
  }
};
import React, { useState } from 'react';

const SortBy = ({ products, setSortedProducts }) => {
  const [sortCriteria, setSortCriteria] = useState('');

  const sortProducts = (criteria) => {
    let sortedProducts = [...products];

    switch (criteria) {
      case 'price':
        sortedProducts.sort((a, b) => a.price - b.price);
        break;
      case 'name':
        sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'ratings':
        sortedProducts.sort((a, b) => b.ratings - a.ratings);
        break;
      case 'mostPopular':
        sortedProducts.sort((a, b) => b.stars - a.stars);
        break;
      case 'shoptools':
        sortedProducts.sort((a, b) => b.categories - a.categories);
        break;
      case 'Interior':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'exterior':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'timing':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'engine':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'suspension':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'brakes':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'transmission':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'exhaust':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'cooling':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'fuel':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'electrical':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'air':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'tools':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'garage':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'accessories':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'tires':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'wheels':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'performance':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'body':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'lighting':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      case 'repair':
        sortedProducts.sort((a, b) => b.shoptools - a.shoptools);
        break;
      default:
        break;
    }

    setSortedProducts(sortedProducts);
  };

  const handleSortChange = (event) => {
    const criteria = event.target.value;
    setSortCriteria(criteria);
    sortProducts(criteria);
  };

  return (
    <div className="sortby-container">
      <label htmlFor="sortby">Sort By: </label>
      <select id="sortby" value={sortCriteria} onChange={handleSortChange}>
        <option value="">Select</option>
        <option value="price">Price</option>
        <option value="name">Name</option>
        <option value="ratings">Ratings</option>
        <option value="mostPopular">Most Popular</option>
        <option value="shoptools">shoptools</option>
        <option value="Interior">Interior</option>
        <option value="exterior">Exterior</option>
        <option value="timing">Timing</option>
        <option value="engine">Engine</option>
        <option value="suspension">Suspension</option>
        <option value="brakes">Brakes</option>
        <option value="transmission">Transmission</option>
        <option value="exhaust">Exhaust</option>
        <option value="cooling">Cooling</option>
        <option value="fuel">Fuel</option>
        <option value="electrical">Electrical</option>
        <option value="air">Air</option>
        <option value="tools">Tools</option>
        <option value="garage">Garage</option>
        <option value="accessories">Accessories</option>
        <option value="tires">Tires</option>
        <option value="wheels">Wheels</option>
        <option value="performance">Performance</option>
        <option value="body">Body</option>
        <option value="lighting">Lighting</option>
        <option value="repair">Repair</option>
        
      </select>
    </div>
  );
};

export default SortBy;

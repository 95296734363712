import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, fetchArticles, fetchCars, fetchYears, addToCart } from '../../redux/actions/ShopActions';
import "./shop.css"; // Make sure to import your CSS file
import { Link } from 'react-router-dom';
import Categories from "../../components/categories/Categories";
import Banner from "../../components/banner/banner";
import Header from "../../components/header/header";
import NavBar from "../../components/navbar/Navbar";
import StarRating from "../../pages/DetailedPage/DetailedPage";
import PopularProducts from "../../components/popularProduct/PopularProducts";
import Clients from "../../components/clients/Clients";
import SortBy from "../../components/SortBy/Sortby";
import CarDetails from "../../components/CarDetails/CarDetails";
import ChatWidget from "../../components/ChatWidget/ChatWidget";
import News from "../../components/news/News";
// Skeleton Component for Shop Items
// const ShopItemSkeleton = () => (
//   <div className="shop-item-skeleton">
//     <div className="skeleton-image">
//       <div className="skeleton-text"></div>
//     </div>
//     <div className="skeleton-text"></div>
//     <div className="skeleton-text"></div>
//   </div>
// );

// // Skeleton Component for Articles
// const ArticleSkeleton = () => (
//   <div className="article-skeleton">
//     <div className="skeleton-image"></div>
//     <div className="skeleton-text"></div>
//     <div className="skeleton-text"></div>
//   </div>
// );

// // Skeleton Component for Cars
// const CarSkeleton = () => (
//   <div className="car-skeleton">
//     <div className="skeleton-image"></div>
//     <div className="skeleton-text"></div>
//     <div className="skeleton-text"></div>
//   </div>
// );

// const Slider = ({
//   min = 0,
//   max = 100,
//   defaultValue,
//   onValueChange,
//   ...rest
// }) => {
//   return (
//     <input
//       type="range"
//       min={min}
//       max={max}
//       defaultValue={defaultValue}
//       onChange={({ target: { value } }) => onValueChange(value)}
//       {...rest}
//     />
//   );
// };

const Shop = () => {
  // const dispatch = useDispatch();
  // const { products, articles, cars, years, cartItems, loading, error } = useSelector((state) => state.shop);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState();
  // const [searchQuery, setSearchQuery] = useState("");
  // const [sortOrder, setSortOrder] = useState("ASC"); // State for sort order
  // const [selectedYear, setSelectedYear] = useState("");
  // const [price, setprice] = useState([]);

  // useEffect(() => {
  //   dispatch(fetchYears());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(fetchProducts(currentPage, sortOrder));
  //   dispatch(fetchArticles());
  //   dispatch(fetchCars(selectedYear));
  // }, [dispatch, currentPage, sortOrder, selectedYear]);

  // const handleSearchChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  // const handleSortOrderChange = (event) => {
  //   setSortOrder(event.target.value);
  // };

  // const handleYearChange = (event) => {
  //   setSelectedYear(event.target.value);
  // };

  // const filteredProducts = products.filter(product =>
  //   product.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // const handlePageChange = (page) => {
  //   setCurrentPage(page + 1);
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // };

  // const handleSliderChange = (value) => {
  //   setprice(value);
  //   console.log(value); // Or any other logic to handle the slider value change
  // };

  // const handleAddToCart = (productId, quantity) => {
  //   dispatch(addToCart(productId, quantity));
  // };

  return (
    <div>
      <Clients/>
      <Header />
      <CarDetails />
      <PopularProducts/>
      <News/>
      <ChatWidget/>
     </div>
  );
};

export default Shop;

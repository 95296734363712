import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCarDetails, toggleCarDetail, addCarDetail } from '../../redux/actions/ShopActions';
import './CarDetails.css'; // Optional: import a CSS file for styling

const CarDetails = () => {
    const dispatch = useDispatch();
    const { cardetails, loading, error } = useSelector(state => state.shop);

    useEffect(() => {
        dispatch(fetchCarDetails());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchCarDetails());
    }, [ dispatch]);

    const handleToggle = (index) => {
        const updatedCarDetails = cardetails.map((carDetail, idx) => {
            if (index === idx) {
                return { ...carDetail, isOpen: !carDetail.isOpen };
            }
            return carDetail;
        });
        dispatch(toggleCarDetail(updatedCarDetails));
    };

    const handleAddCarDetail = (newCarDetail) => {
        dispatch(addCarDetail(newCarDetail));
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="car-details-container">
            {cardetails.map((carDetail, index) => (
                <div key={index} className="car-detail-item">
                    <button className="close-button" onClick={() => handleToggle(index)}>
                        {carDetail.isOpen ? 'Close' : 'Open'}
                    </button>
                    {carDetail.isOpen && (
                        <>
                            <h2>Your Car Details</h2>
                            <p>
                                <strong>Year:</strong> {carDetail.year}
                            </p>
                            <p>
                                <strong>Manufacturer:</strong> {carDetail.manufacturer}
                            </p>
                            <p>
                                <strong>Model:</strong> {carDetail.model}
                            </p>
                            <p>
                                <strong>Body Style:</strong> {carDetail.body_styles}
                            </p>
                            <p>
                                <strong>Engine:</strong> {carDetail.engine}
                            </p>
                        </>
                    )}
                </div>
            ))}
            {/* Add button to add new car details for testing */}
            {/* <button onClick={() => handleAddCarDetail({ year: 2022, manufacturer: 'Toyota', model: 'Corolla', body_styles: 'Sedan', engine: '1.8L' })}>
                Add Car
            </button> */}
        </div>
    );
};

export default CarDetails;

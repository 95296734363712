import React from "react";
const About = () => {
  return (
    <div>
      <section className="page-title  v3 clearfix parallax  parallax5">
        <div className="overlay"></div>
        <div className="container">
          <div className="wrap-page-title">
            <div className="breakcrums text-center v2">
              <ul>
                <li>
                  <a href="#" className="hover-text">
                    HOME
                  </a>
                </li>
                <li>
                  <a href="#" className="hover-text">
                    PAGES
                  </a>
                </li>
                <li>
                  <a href="#" className="hover-text">
                    ABOUT COMPANY
                  </a>
                </li>
              </ul>
            </div>

            <div className="page-title-heading text-center v2">
              <h1>
                <a href="#" className="hover-text">
                  ABOUT COMPANY
                </a>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <article className="content-wrap">
        <div
          className="flat-spacer clearfix"
          data-desktop="99"
          data-mobile="99"
          data-smobile="99"
        ></div>
        <div className="container clearfix">
          <div className="sidebar-left">
            <div className="widget widget-categories v1">
              <ul>
                <li>
                  <a href="#">About Our Company</a>
                </li>
                <li>
                  <a href="#">Awards & Recognition</a>
                </li>
                <li>
                  <a href="#">Career Opportunities</a>
                </li>
                <li>
                  <a href="#">Exclusive Offers</a>
                </li>
                <li>
                  <a href="#">Maintenance Packages</a>
                </li>
              </ul>
            </div>
            <div className="widget widget-deal">
              <h2 className="title-heading">DEAL TODAY</h2>
              <div className="img">
                <img src="/assets/images/FAQs/men-deal.png" alt="AutoMov" />
              </div>
              <div className="textbox">
                <h3 className="title">GET 10% OFF TODAY</h3>
                <p>
                  A Completely Safe and Advanced Cleaning Solution for both
                  Petrol and Diesel Engines
                </p>
                <div className="wrap-btn">
                  <a href="#" className="flat-button gb-detail">
                    DETAILS
                  </a>
                </div>
              </div>
            </div>

            <div className="widget-testimonials flat-border-bottom pd-bottom-30">
              <div
                className="flat-carousel-not-numb"
                data-auto="false"
                data-column="1"
                data-column2="1"
                data-column3="1"
                data-gap="1"
                data-dots="false"
                data-nav="false"
                data-loop="true"
              >
                <div className="flat-testimonials  owl-carousel ">
                  <div className="testimonial style1 v2">
                    <blockquote>
                      "I have taken several of the family cars here for the past
                      several years and without exception the experiences have
                      been outstanding. I would highly recommend this place to
                      any one who wants great service, honest value, and really
                      great people."
                    </blockquote>
                    <div className="customer-info">
                      <h5 className="name">
                        <a href="#" className="hover-text">
                          JOHN SMITH
                        </a>
                      </h5>
                      <p className="position">Chief Director</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-page-wrap about-company-wrap pd-left-60">
            <div className="flat-about flat-border-bottom pd-bottom-30">
              <div className="img">
                <img
                  src="/assets/images/company/about-company/big-img.png"
                  alt="AutoMov"
                />
              </div>
              <h3 className="heading">Our commitment towards customer: </h3>

              <p>
              FredAutoRepair is a leading online store offering a wide range of car parts and accessories, providing high-quality products, fast shipping, and excellent customer service.
              </p>
              <div className=" video-list list clearfix">
                <ul className="list">
                  <li>
                    <a href="#">24 -Hours Emergency Services</a>
                  </li>
                  <li>
                    <a href="#">Uniformed, Licensed mechanic</a>
                  </li>
                  <li>
                    <a href="#">No Travel Charges</a>
                  </li>
                  <li>
                    <a href="#">Licensed and Insured</a>
                  </li>
                  <li>
                    <a href="#">Free Estimates</a>
                  </li>
                </ul>

                <ul className="list">
                  <li>
                    <a href="#">Management and supervision</a>
                  </li>
                  <li>
                    <a href="#">Quality construction</a>
                  </li>
                  <li>
                    <a href="#">Absolute safety</a>
                  </li>
                  <li>
                    <a href="#">Ensure the progress</a>
                  </li>
                  <li>
                    <a href="#">Timely supply</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="flat-company-overviews">
              <div className="flat-title v4">
                <h3>Company overviews</h3>
              </div>
              <div className="flat-iconbox clearfix fix-float-box">
                <div className="iconbox one-of-two style1">
                  <div className="iconbox-border">
                    <div className="iconbox-icon">
                      <span className="icon-car"></span>
                    </div>
                    <div className="iconbox-content">
                      <h5>
                        <a href="#">Free Maintenance</a>
                      </h5>
                      <p>
                      "Get free maintenance! Well, not really, but our deals are still amazing!" {" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="iconbox one-of-two style1">
                  <div className="iconbox-border">
                    <div className="iconbox-icon">
                      <span className="icon-bottle"></span>
                    </div>
                    <div className="iconbox-content">
                      <h5>
                        <a href="#">Free Oil Change</a>
                      </h5>
                      <p>
                      "Free oil changes! Okay, not quite, but you'll love our prices!"{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="iconbox one-of-two style1">
                  <div className="iconbox-border">
                    <div className="iconbox-icon">
                      <span className="icon-flower"></span>
                    </div>
                    <div className="iconbox-content">
                      <h5>
                        <a href="#">Repair The Air System</a>
                      </h5>
                      <p>
                      "We’ll repair the air system! Just kidding, but our deals are cool!"{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="iconbox one-of-two style1">
                  <div className="iconbox-border">
                    <div className="iconbox-icon">
                      <span className="icon-hydrant"></span>
                    </div>
                    <div className="iconbox-content">
                      <h5>
                        <a href="#">Free Gas Pump</a>
                      </h5>
                      <p>
                      "Free gas with every purchase! Just kidding, but we do have great discounts!"{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flat-our-mission style2">
              <div className="flat-title v4">
                <h3>Our Mission</h3>
              </div>
              <p className="description">
                We offer a full range of garage services to vehicle owners
                located in the Montreal area. All mechanic services are performed by
                highly qualified mechanics. We can handle any car problem.
              </p>

              <div className="mission clearfix flat-border-bottom">
                <div className="img-single">
                  <div className="img ">
                    <img
                      src="/assets/images/company/about-company/mission-1.png"
                      alt="AutoMov"
                    />
                  </div>
                </div>
                <div className="text-content pd-left-15">
                  <p>
                    We offer a full range of garage services to vehicle owners
                    in Montreal. Our professionals know how to handle a wide range
                    of car services.{" "}
                  </p>

                  <p>
                    Whether you drive a passenger car or medium sized truck or
                    SUV, our mechanics strive to ensure that your vehicle will
                    be performing at its best before leaving our car shop.
                  </p>
                </div>
              </div>

              <div className="mission clearfix ">
                <div className="text-content pd-right-15 text-right">
                  <p>
                    We offer a full range of garage services to vehicle owners
                    in Montreal. Our professionals know how to handle a wide range
                    of car services.{" "}
                  </p>

                  <p>
                    Whether you drive a passenger car or medium sized truck or
                    SUV, our mechanics strive to ensure that your vehicle will
                    be performing at its best before leaving our car shop.
                  </p>
                </div>
                <div className="img-single  text-right">
                  <div className="img ">
                    <img
                      src="/assets/images/company/about-company/mission-2.png"
                      alt="AutoMov"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flat-spacer clearfix"
          data-desktop="5"
          data-mobile="5"
          data-smobile="5"
        ></div>
      </article>

      <section className="flat-contact v1 clearfix">
        <div className="container">
          <div className="wrap-text">
            <h3 className="title">
              <a href="#">
                Have you question or need any help for work consulant?
              </a>
            </h3>
          </div>
          <div className="wrap-btn text-right">
            <a href="/appointment" className="flat-button btn-contact bg-contact">
              Contact us
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;

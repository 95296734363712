import React, { useRef } from "react";
import { useHistory } from "react-router-dom"; // Updated for React Router v6
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { addUser, removeUser } from "./../redux/actions/index";

const User = () => {
  // Accessing the user state from the Redux store
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const userRef = useRef(null); // Corrected variable name to camelCase

  // Handle form submission to add a user
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addUser(userRef.current.value));
    userRef.current.value = "";
    toast.success("User Added Successfully"); // Corrected typo
  };

  // Handle removing a user
  const handleRemoveUser = (index) => {
    dispatch(removeUser(index));
    toast.warn("User Removed Successfully"); // Corrected typo
  };

  // Navigate back to the previous page
  const navigate = useHistory(); // Updated for React Router v6
  const handleRoute = () => {
    navigate("/"); // Updated for React Router v6
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <h2>User Component Using Hooks and Redux Example</h2>
        <input type="text" ref={userRef} />
      </form>

      <ul>
        {user.map((item, index) => (
          <li key={index}>
            {item.name}
            <button onClick={() => handleRemoveUser(index)}>&times;</button> {/* Corrected function name */}
          </li>
        ))}
      </ul>
      <button onClick={handleRoute}>Go Back</button>
    </>
  );
};

export default User;

import React from "react";
const Faqs = () => {
  return (
    <div>
      <section className="page-title  v3 clearfix parallax  parallax5">
        <div className="overlay"></div>
        <div className="container">
          <div className="wrap-page-title">
            <div className="breakcrums text-center v2">
              <ul>
                <li>
                  <a href="#" className="hover-text">
                    HOME
                  </a>
                </li>
                <li>
                  <a href="#" className="hover-text">
                    PAGES
                  </a>
                </li>
                <li>
                  <a href="#" className="hover-text">
                    FAQs
                  </a>
                </li>
              </ul>
            </div>

            <div className="page-title-heading text-center v2">
              <h1>
                <a href="#" className="hover-text">
                  FAQs
                </a>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <article className="content-wrap">
        <div
          className="flat-spacer clearfix"
          data-desktop="99"
          data-mobile="99"
          data-smobile="99"
        ></div>
        <div className="container clearfix">
          <div className="sidebar-left">
            <div className="widget widget-categories v1">
              <ul>
                <li>
                  <a href="#">About Our Company</a>
                </li>
                <li>
                  <a href="#">Awards & Recognition</a>
                </li>
                <li>
                  <a href="#">Career Opportunities</a>
                </li>
                <li>
                  <a href="#">Exclusive Offers</a>
                </li>
                <li>
                  <a href="#">Maintenance Packages</a>
                </li>
              </ul>
            </div>
            <div className="widget widget-deal">
              <h2 className="title-heading">DEAL TODAY</h2>
              <div className="img">
                <img src="/assets/images/FAQs/men-deal.png" alt="AutoMov" />
              </div>
              <div className="textbox">
                <h3 className="title">GET 10% OFF TODAY</h3>
                <p>
                  A Completely Safe and Advanced Cleaning Solution for both
                  Petrol and Diesel Engines
                </p>
                <div className="wrap-btn">
                  <a href="#" className="flat-button gb-detail">
                    DETAILS
                  </a>
                </div>
              </div>
            </div>

            <div className="widget-testimonials flat-border-bottom pd-bottom-30">
              <div
                className="flat-carousel-not-numb"
                data-auto="false"
                data-column="1"
                data-column2="1"
                data-column3="1"
                data-gap="1"
                data-dots="false"
                data-nav="false"
                data-loop="true"
              >
                <div className="flat-testimonials  owl-carousel ">
                  <div className="testimonial style1 v2">
                    <blockquote>
                      "I have taken several of the family cars here for the past
                      several years and without exception the experiences have
                      been outstanding. I would highly recommend this place to
                      any one who wants great service, honest value, and really
                      great people."
                    </blockquote>
                    <div className="customer-info">
                      <h5 className="name">
                        <a href="#" className="hover-text">
                          JOHN SMITH
                        </a>
                      </h5>
                      <p className="position">Chief Director</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-page-wrap faqs-wrap pd-left-60">
            <div
              className="flat-spacer clearfix"
              data-desktop="0"
              data-mobile="60"
              data-smobile="60"
            ></div>
            <div className="flat-question style2">
              <div className="flat-title v4">
                <h3 className="title">Frequently Ask Questions</h3>
              </div>
              <p>
                We may not have the right answers to all of your questions, but we will provide you with the answers you need.
              </p>
              <div
                className="flat-spacer clearfix"
                data-desktop="33"
                data-mobile="33"
                data-smobile="33"
              ></div>
              <div className="accordion">
                <div className="accordion-toggle">
                  <div className="toggle-title active">
                    Do you repair all types of cars?
                  </div>
                  <div className="toggle-content">
                    <p>
                      Yes, we carry parts for all types of cars.
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="accordion-toggle">
                  <div className="toggle-title ">
                    How can I find out about the maintenance schedule for my
                    vehicle?
                  </div>
                  <div className="toggle-content">
                    <p>
                      Give us a call and we will veirfy for you and get back to you.
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="accordion-toggle">
                  <div className="toggle-title">
                    How do I keep track of routine maintenance?
                  </div>
                  <div className="toggle-content">
                    <p>
                      Our smart website allows you to keep track of routine by sending you a reminder.
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="accordion-toggle">
                  <div className="toggle-title">
                    How often should I contact you for an oil change?
                  </div>
                  <div className="toggle-content">
                    <p>
                      3 times a year
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="accordion-toggle">
                  <div className="toggle-title">
                    Does my warranty hold if I do not stick to the recommended
                    schedule for activities?
                  </div>
                  <div className="toggle-content">
                    <p>
                      No, but there are exceptions.
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="accordion-toggle">
                  <div className="toggle-title">
                    How often should I contact you for an oil change?
                  </div>
                  <div className="toggle-content">
                    <p>
                      anytime you want! Our website allows you to keep track of routine by sending you a reminder.  
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="accordion-toggle">
                  <div className="toggle-title">
                    How can I find out about the maintenance schedule for my
                    vehicle?
                  </div>
                  <div className="toggle-content">
                    <p>
                      On our website, you can find out about the maintenance
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flat-search-form bg-f6f6f6">
              <form action="#" className="clearfix">
                <div className="wrap-input">
                  <input type="search" name="search" placeholder="Search" />
                </div>
                <div className="wrap-btn">
                  <button type="submit" className="btn-effect btn-search">
                    Search
                  </button>
                </div>
              </form>
            </div>

            <div className="flat-support ">
              <div className="flat-title v4">
                <h3 className="title">Support Services</h3>
              </div>
              <div className="accordion">
                <div className="accordion-toggle">
                  <div className="toggle-title active">
                    Should I consider using synthetic motor oil?
                  </div>
                  <div className="toggle-content">
                    <p>
                      depends on the year of the car and the type of engine.
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="accordion-toggle">
                  <div className="toggle-title ">
                    What parts should be replaced at what intervals?
                  </div>
                  <div className="toggle-content">
                    <p>
                      day to day parts, brakes, tires, transmission, etc
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="accordion-toggle">
                  <div className="toggle-title">
                    How do I keep track of routine maintenance?
                  </div>
                  <div className="toggle-content">
                    <p>
                      Singing to our website allows you to keep track of routine
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flat-spacer clearfix"
          data-desktop="60"
          data-mobile="60"
          data-smobile="60"
        ></div>
      </article>

      <section className="flat-contact v1 clearfix">
        <div className="container">
          <div className="wrap-text">
            <h3 className="title">
              <a href="#">
                Have you question or need any help for work consulant?
              </a>
            </h3>
          </div>
          <div className="wrap-btn text-right">
            <a href="/appointment" className="flat-button btn-contact bg-contact">
              Contact us
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faqs;

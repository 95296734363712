import {

FETCH_PRODUCTS_REQUEST,
FETCH_PRODUCTS_SUCCESS,
FETCH_PRODUCTS_FAILURE,
FETCH_ARTICLES_REQUEST,
FETCH_ARTICLES_SUCCESS,
FETCH_ARTICLES_FAILURE,
FETCH_CARS_REQUEST,
FETCH_CARS_SUCCESS,
FETCH_CARS_FAILURE,
FETCH_YEARS_REQUEST,
FETCH_YEARS_SUCCESS,
FETCH_YEARS_FAILURE,
ADD_TO_CART_SUCCESS,
FETCH_USER_SUCCESS,
FETCH_CART_REQUEST,
FETCH_CART_SUCCESS,
ADD_TO_CART,
REMOVE_CART_ITEM_SUCCESS,
ADD_TO_CART_FAILURE,
UPDATE_TOTAL_ITEMS,
SET_SEARCH_RESULTS,
FETCH_CAR_DETAILS_REQUEST, 
FETCH_CAR_DETAILS_SUCCESS, 
FETCH_CAR_DETAILS_FAILURE ,
REMOVE_CART_ITEM,
UPDATE_TOTAL_PRICE,
TOGGLE_CAR_DETAIL,
ADD_CAR_DETAIL,

} from '../actions/actionTypes';

const initialState = {
    cardetails: [],
    products: [],
    cart: [],
    articles: [],
    cars: [],
    years: [],
    cartItems: [],
    loading: false,
    user: null,
    totalPrice: 0,
    totalItems: 0,
    searchResults: [],
    error: null
};

const shopReducer = (state = initialState, action) => {
switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
    case FETCH_ARTICLES_REQUEST:
    case FETCH_CARS_REQUEST:
    case FETCH_YEARS_REQUEST:
        return { ...state, loading: true, error: null };

    case FETCH_PRODUCTS_SUCCESS:
        return { ...state, loading: false, products: action.payload };
    case FETCH_ARTICLES_SUCCESS:
        return { ...state, loading: false, articles: action.payload };
    case FETCH_CARS_SUCCESS:
        return { ...state, loading: false, cars: action.payload };
    case FETCH_YEARS_SUCCESS:
        return { ...state, loading: false, years: action.payload };
    case FETCH_USER_SUCCESS:
        return {
            ...state,
            user: action.payload,
        };
    case FETCH_CART_REQUEST:
        return { ...state, loading: true, cartItems: action.payload };
        case ADD_TO_CART_SUCCESS:
            return { ...state, loading: true, cartItems: action.payload.items };
    case FETCH_CART_SUCCESS:
        return {
            ...state,
            loading: false,
            cartItems: action.payload,
            cart: action.payload,
            totalPrice: action.payload,
            totalItems: action.payload.items.reduce((total, item) => total + item.quantity, 0),
        };
    case REMOVE_CART_ITEM_SUCCESS:
        return {
            ...state,
            cartItems: action.payload.cartItems,
            totalPrice: action.payload.totalPrice,
            totalItems: action.payload.totalItems,
            
        };
    case UPDATE_TOTAL_ITEMS:
        return {
            ...state,
            totalItems: action.payload,
        };
    case FETCH_PRODUCTS_FAILURE:
    case FETCH_ARTICLES_FAILURE:
    case FETCH_CARS_FAILURE:
    case FETCH_YEARS_FAILURE:
        return { ...state, loading: false, error: action.error };

    // Remove the duplicate case label
    case ADD_TO_CART_FAILURE:
        return { ...state, error: action.error };
    // case ADD_TO_CART_FAILURE:
    //     return { ...state, error: action.error };

        case 'shop/updateTotalItems':
            return {
              ...state,
              totalItems: action.payload,
            };
            case SET_SEARCH_RESULTS:
                return {
                  ...state,
                  searchResults: action.payload,
                };
        case FETCH_CAR_DETAILS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_CAR_DETAILS_SUCCESS:
            return { ...state, loading: false, cardetails: action.payload };
        case FETCH_CAR_DETAILS_FAILURE:
            return { ...state, loading: false, error: action.payload };
            // case SET_SEARCH_RESULTS:
            //     return { ...state, searchResults: action.payload };
            case ADD_TO_CART:
                const updatedCart = [...state.cartItems, action.payload];
                const updatedTotalPrice = updatedCart.reduce((sum, item) => sum + item.amount * item.quantity, 0);
                return { ...state, cartItems: updatedCart, totalPrice: updatedTotalPrice, totalItems: updatedCart.length };
            case REMOVE_CART_ITEM:
                const filteredCart = state.cartItems.filter(item => item.id !== action.payload);
                const newTotalPrice = filteredCart.reduce((sum, item) => sum + item.amount * item.quantity, 0);
                return { ...state, cartItems: filteredCart, totalPrice: newTotalPrice, totalItems: filteredCart.length };
            case UPDATE_TOTAL_PRICE:
                return { ...state, totalPrice: action.payload };
                case TOGGLE_CAR_DETAIL:
            return {
                ...state,
                cardetails: action.payload,
            };
            case ADD_CAR_DETAIL:
            return {
                ...state,
                cardetails: [...state, action.payload],
            };
    default:
        return state;
}
};

export default shopReducer;

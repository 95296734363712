import React, { useEffect, useState } from 'react';
import { DropdownMenu } from '../dropdown/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import './header.css';
import { fetchProducts, setSearchResults } from '../../redux/actions/ShopActions';

function Header() {
  const dispatch = useDispatch();
  const products = useSelector(state => state.shop.products);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (products) {
      const filtered = products.filter(product =>
        (product.name && product.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (product.keywords && product.keywords.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      dispatch(setSearchResults(filtered));
    }
  }, [searchQuery, products, dispatch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <header className="header1">
      <div className="header-top">
        <DropdownMenu className="dropdown-menu" />
        <div className="header-top__left"></div>
        <div className="header-top__right"></div>
      </div>
      <div className="header-bottom1">
        <form onSubmit={handleSearchSubmit}>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="What part do you need today, VIN number, car model?"
            aria-label="Search for parts"
            tabIndex="0"
          />
          <button type="submit">Search</button>
        </form>
      </div>
    </header>
  );
}

export default Header;
